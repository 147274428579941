import React, { useEffect } from 'react'
import SustainabilityJourney from '../components/SustainabilityJourney'
import Line from '../shapes/Line'
import LinkedIn from '../assets/images/homescreen/link.svg'
import Face from '../assets/images/homescreen/face.svg'
import Tweet from '../assets/images/homescreen/tweet-black.svg'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { getAllSustainabilities } from '../features/sustainability/sustainabilitySlice'
import StripTags from '../utils/StripTags'
import { getBanner } from '../features/banners/bannerSlice'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import moment from 'moment';
import LoadingComponent from '../components/LoadingComponent'
import config from '../config'
import SEO from '../components/SEO'

const Sustainability = () => {

    const [reports, setReports] = useState([]);
    const [journeys,setJourneys] = useState([]);
    const [priorities,setPriorities] = useState([]);
    const [insights,setInsights] = useState([]);

    const { banner, isLoading} = useSelector((state) => state.banner);
    const dispatch = useDispatch();

    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }
   
    const location = useLocation();
    const slug = location?.pathname.split('/')[1];
  
    useEffect(() => {
        dispatch(getBanner(slug));
    },[dispatch])


    useEffect(() => {
        const fetchData = async () => {
            const data = await dispatch(getAllSustainabilities())
            setReports(data?.payload?.report)
            setJourneys(data?.payload?.journeys)
            setPriorities(data?.payload?.priorities)
            setInsights(data?.payload?.insights)
        }

        fetchData()

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        
    },[dispatch])

  return (
    <>
        <h1 className='heading-title'>SUSTAINABILITY</h1>
        <SEO
            title="Sustainability | Net Zero and The Just Transition | Yellow Door Energy"
            description="Leading the sustainable energy transition! Reduce carbon emissions with our distributed solar solutions. Explore Yellow Door Energy's commitment to ESG principles. Together, let's advance toward a net-zero future."
            url={window.location.href}
        />
        {isLoading && (
            <div className={`loading-screen ${isLoading ? '' : 'hidden'}`}>
                <LoadingComponent />
            </div>
        )}

         <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[344px]'>
                <img width="1600" height="800" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[37%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[76%] w-full '>
                </div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                    <div className='lg:ml-auto lg:w-[70%] lg:pl-[30px]'>
                        <div className='w-full w-full'>
                            <span className='uppercase text-[#ffffff] text-2xl lg:text-xl font-gotham-bold'>{banner?.title_en}</span>
                        </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff] lg:text-base  2xl:text-lg my-3 inline-block '>
                              <StripTags policyText={banner?.text_en} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <section className='journey'>
                <SustainabilityJourney title={false} image={journeys[0]?.image ? journeys[0]?.image : ''} name={journeys[0]?.name_en ? journeys[0]?.name_en : '' } role={journeys[0]?.role_en ? journeys[0]?.role_en : ''} text={journeys[0]?.text_en ? journeys[0]?.text_en : ''} />
            </section>

            {reports?.map((report,id) => {
                   const shareURL = encodeURIComponent(`${window.location.origin}${window.location.pathname}`);
                   const title = encodeURIComponent(report?.title_en);
                   const hashtags = encodeURIComponent('yellowdoorenergy');
                   const linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`;
                   const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`;
                   const twitterShareLink = `https://twitter.com/intent/tweet?url=${shareURL}&text=${title}&hashtags=${hashtags}`;
                    return (
                

                    <section key={id} className='screen-1 my-4'>  
                        <div>
                            <span className='uppercase text-primary-500 2xl:text-2xl text-xl'>OUR</span>
                            <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                                <span className='uppercase text-primary-500 2xl:text-3xl text-xl min-w-max font-gotham-black'>{report?.title_en}</span>
                                <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                                    <Line width={60} />
                                    <Line width={120} />
                                </div>
                            </div>
                        </div>
        
                        <div className='flex flex-col lg:flex-row items-center my-8'>
                        <Link to={report?.pdf} target="_blank" rel="noopener noreferrer">
                            <img width="88.4" height="70" className='object-contain w-[250px]' src={report?.image} alt='Reports' />
                        </Link>
                            <div className='lg:w-[70%] lg:ml-auto my-6 lg:my-0'>
                                <p className='text-base 2x:text-lg text-[#101010]'>
                                    <StripTags policyText={report?.text_en} />
                                </p>
                                <div className='flex space-x-4 items-baseline my-4'>
                                    <span>Share:</span>
                                    <a href={linkedInShareLink}  target="_blank">
                                        <img width="" height="" className='object-contain w-[15px] h-[15px]' src={LinkedIn} alt='LinkedIn'  />
                                    </a>
                                    <a href={facebookShareLink}  target="_blank">
                                        <img width="" height="" className='object-contain w-[15px] h-[15px]' src={Face} alt='Facebook'  />
                                    </a>
                                    <a href={twitterShareLink}  target="_blank">
                                        <img width="" height="" className='object-contain w-[15px] h-[15px]' src={Tweet} alt='Twitter'  />
                                    </a>
                                </div>
                                <div>
                                    <Link to={report?.pdf} target="_blank" rel="noopener noreferrer">
                                        <button className='uppercase text-base 2xl:text-lg rounded-tl-[10px] font-gotham-medium rounded-br-[10px] text-primary-500 bg-secondary-500 hover:bg-primary-500 
                                        w-[35%] lg:w-[20%] hover:text-secondary-500 border border-secondary-500 duration-300 ease-in'>
                                            
                                            PDF
                                            
                                        </button>
                                    </Link>
                                </div>     
                            </div>
                        </div>
                    </section>
                    
                )
                
            })}

            <section className='screen-1'>
                <div className='my-8'>
                    <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Our</span>
                    <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                        <span className='uppercase  text-primary-500 min-w-max 2xl:text-3xl text-xl font-gotham-black leading-none'>SUSTAINABILITY PRIORITIES</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                {priorities?.map((priority,id) => (
                    <div key={id} className='flex flex-col lg:flex-row justify-between my-12'>
                        <img width="" height="" className='object-contain w-[80px]' src={priority?.icon} alt={priority?.title_en} />
                        <span className='font-gotham-black text-xl 2xl:text-3xl lg:w-[20%] lg:ml-auto text-primary-500'>
                            <span className='font-gotham-book'>{priority?.title_en}</span><br />{priority?.subtitle_en}
                        </span>
                        <div className='lg:w-[60%] mr-auto text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'>
                            <StripTags policyText={priority?.text_en} />
                        </div>
                    </div>
                ))}
            </section>

        <section className='screen-1 mt-12'>
        <div className='flex items-end justify-between py-3'>
            <div>
                <span className='uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Latest</span>
                <div className='flex flex-col md:flex-row items-center md:space-x-6'>
                    <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Insights</span>
                    <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                        <Line width={60} />
                        <Line width={120} />
                    </div>
                </div>
            </div>
            <div >
                <span className='md:text-base text-xs  w-full inline-block'>
                    <Link to={'/insights'} className='text-primary-500 hover:text-secondary-500'>View All Insights</Link>
                </span>
            </div>
        </div>
        <div className='grid lg:grid-cols-4 md:grid-cols-3  gap-4'>
                {insights?.map((insight,id) => (
                        <div key={id} className={`group lg:col-span-${id == 0 ? 2 : 1}`}>

                        <Link to={`/insights/${insight?.slug}`}>
                            <div className='image-container'><img src={insight?.image} alt={insight?.title_en} /></div>
                            <div className='py-4 sm:px-5'>
                                <p className='text-primary-500 font-gotham-bold uppercase 2xl:text-lg text-base group-hover:text-secondary-500 cursor-pointer duration-300 ease-in-out'>
                                    {insight?.title_en}
                                </p>
                                <p className=' 2xl:text-base text-sm'>{insight?.insight?.title_en}</p>
                                <p className='text-xs text-primary-500 text-opacity-50  my-2'>{moment(insight?.date).format('LL')}</p>
                                <p className='2xl:text-base text-sm'><StripTags policyText={truncate(insight?.text_en,200)} /></p>
                                <div>
                                    <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500 '>Learn More</Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            </section>

            <section className='journey'>
                <SustainabilityJourney title={false} image={journeys[1]?.image} name={journeys[1]?.name_en} role={journeys[1]?.role_en} text={journeys[1]?.text_en} />
            </section>

            <div className='mb-16'>
                <Contact />
            </div>

            <div className='mt-16'>
                <OurOffices />
            </div>
      
    </>
  )
}

export default Sustainability