import { configureStore } from "@reduxjs/toolkit";
import testimonialReducer from "../features/testimonials/testimonialSlice";
import teamReducer from "../features/teams/teamSlice";
import solutionReducer from "../features/solutions/solutionSlice";
import valueReducer from "../features/values/valueSlice";
import projectReducer from "../features/projects/projectSlice";
import marketReducer from "../features/markets/marketSlice";
import aboutReducer from "../features/about-us/aboutUsSlice";
import insightReducer from "../features/insights/insightSlice";
import sustainabilityReducer from "../features/sustainability/sustainabilitySlice";
import applicationReducer from "../features/applications/applicationSlice";
import officeReducer from "../features/offices/officeSlice";
import bannerReducer from "../features/banners/bannerSlice";
import sectionReducer from "../features/fixed-sections/fixedSlice";
import socialReducer from "../features/socials/socialSlice";
import externalReducer from "../features/externals/externalSlice";
import newsletterReducer from "../features/newsletter/newsletterSlice";
import error500Reducer from "../features/error500/error500Slice"


export const store = configureStore({
  reducer: {
    testimonial : testimonialReducer,
    team: teamReducer,
    solution: solutionReducer,
    value: valueReducer,
    project: projectReducer,
    market: marketReducer,
    about: aboutReducer,
    insight: insightReducer,
    sustainability: sustainabilityReducer,
    application: applicationReducer,
    office: officeReducer,
    banner: bannerReducer,
    section: sectionReducer,
    social: socialReducer,
    external: externalReducer,
    newsletter: newsletterReducer,
    error500 :  error500Reducer
  },
});