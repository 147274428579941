import React, { useEffect, useState } from 'react'
import Line from '../shapes/Line'
import { Link } from 'react-router-dom'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { useDispatch, useSelector } from 'react-redux'
import { getProjects, getProjectByMarket, getOldestProject, getProjectByAlpha , getProjectsBySearch } from '../features/projects/projectSlice'
import { getList } from '../features/markets/marketSlice'
import LoadingComponent from '../components/LoadingComponent'
import SEO from '../components/SEO'

const Projects = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [activeFilter, setActiveFilter] = useState(0);

    const handleTabClick = (marketId) => {
        if (marketId === 0) {
          dispatch(getProjects());
          setActiveTab(0);
        } else {
          setActiveTab(marketId);
        }
    };

    const { isLoading, isSuccess, projects } = useSelector((state) => state.project);
    const { markets } = useSelector((state) => state.market);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchList = async () => {
            await dispatch(getList());
        }

        fetchList();
        fetchFilteredProjects();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    }, [activeTab,dispatch]);

    const fetchFilteredProjects = async () => {
        if (activeTab === 0) {
            dispatch(getProjects());
        } else {
            dispatch(getProjectByMarket(activeTab));
        }
    };
  
  return (
    <>
        <h1 className='heading-title'>SUCCESS STORIES</h1>
        <SEO
            title="Solar Projects | Success Stories | Yellow Door Energy"
            description="Explore transformative solar power projects and success stories at Yellow Door Energy. Discover sustainable solutions shaping a greener future."
            url={window.location.href}
        />
      {isLoading && (
        <div className={`loading-screen ${isLoading ? '' : 'hidden'}`}>
          <LoadingComponent />
        </div>
      )}
        <div className='flex flex-col bg-primary-500 w-full mt-[80px] md:mt-[114px]'>
            <div className='screen-1 w-full py-8 sm:pb-8 ' >
                <div className='w-3/4 pb-1'>
                    <span className='uppercase text-[#ffffff] md:text-2xl text-lg leading-none'>Success</span>
                    <div className='flex items-center space-x-6'>
                        <span className='uppercase text-[#ffffff] md:text-2xl text-lg  font-gotham-black'>Stories</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
               <div className='tab w-full border border-[#ffffff] flex flex-col sm:flex-row items-center justify-around mt-2 rounded-bl-[5px] rounded-tr-[5px] rounded-tl-[10px] rounded-br-[10px]'>
                    <div
                        className={`tab-content text-[#ffffff] duration-300 ease-in-out flex-1 px-3 py-1 text-center w-full ${
                        activeTab === 0 ? 'active font-gotham-black' : ''
                        }`}
                        onClick={() => handleTabClick(0)}
                    >
                        <Link className='text-base' >
                            All Markets
                        </Link>
                    </div>
                    {markets?.map(({id,title_en,index}) => (
                        <div key={index}
                            className={`tab-content text-[#ffffff] duration-300 ease-in-out flex-1 px-3 py-1 text-center w-full ${
                            activeTab === id ? 'active font-gotham-black' : ''
                            }`}
                            onClick={() => handleTabClick(id)}
                        >
                            <Link className='text-base' >
                                {title_en}
                            </Link>
                        </div>
                    ))}
                    </div>
            </div>
        </div>
        <div className='screen-1 relative min-h-screen'>
                <div >
                <div className='screen-1 pt-8 pb-14'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-4'>
                        {projects?.map((project,id) => (
                           <>
                            {project?.isYoutube && (
                                <div key={id} className='sm:col-span-2'>
                                    <div>
                                        <iframe
                                            title={project?.title_en}
                                            width='100%'
                                            height='300px'
                                            src={`https://www.youtube.com/embed/${project?.isYoutube.split('/').pop()}`}
                                            frameBorder='0'
                                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            )}
                            {project?.isYoutube == null && (
                                <>
                                    <div>
                                        <div>
                                           
                                            <Link to={`/projects/${project?.slug}`}>
                                            <div className='image-container'>
                                                <img className='' src={project?.image} alt={project?.title_en} />
                                            </div>
                                                <div className='py-4 px-5'>
                                                    <p className='text-primary-500 font-gotham-bold uppercase text-base 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                                        {project?.title_en}
                                                    </p>
                                                    <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.mwp_en}</p>
                                                    <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.location_en}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )}    
                            </>
                        ))}
                    </div>
                </div>
                </div>
    
            <div>
               
            </div>
        </div>

        <div className='mb-32'>
            <Contact />
        </div>

        <div className='mt-32'>
            <OurOffices />
        </div>
    </>
  )
}

export default Projects