import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import externalService from "./externalService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  policy: [],
  career: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCareer = createAsyncThunk(
  "links/getCareer",
  async (_, thunkApi) => {
    try {
      return await externalService.getCareer();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getPolicy = createAsyncThunk(
  "links/getPolicy",
  async (_, thunkApi) => {
    try {
      return await externalService.getPolicy();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 500) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const externalSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCareer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.career = action.payload;
      })
      .addCase(getPolicy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.policy = action.payload;
      })
  },
});

export const { reset } = externalSlice.actions;
export default externalSlice.reducer;