import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/banner`;

const getBanner = async (slug) => {
    const response = await axios.get(API_URL + `/${slug}`);
    return response.data;
};

const bannerService = {
    getBanner,
};

export default bannerService;