import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOffice } from '../features/offices/officeSlice'
import Line from '../shapes/Line'
import StripTags from '../utils/StripTags'

const OurOffices = () => {

    const { offices } = useSelector((state) => state.office)
    const dispatch = useDispatch();

    useEffect(() => {

        const fetchData = async () => {
            dispatch(getOffice());
        }

        fetchData()
    }, [dispatch])

    return (
        <>
            <div className='screen-1 py-3'>
                <div >
                    <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Our</span>
                    <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                        <span className='uppercase text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>Offices</span>
                        <div className='space-y-1 mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-primary-500 py-8'>
                <div className='screen-1'>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-x-16 gap-y-8 bg-primary-500 '>
                        {offices?.map((office, id) => (
                            <div key={id}>
                                <p className=' font-gotham-black uppercase text-secondary-500  text-xl leading-loose'>{office?.title_en}</p>
                                <a href={`tel:${office?.mobile_en}`} className='font-gotham-bold text-base   text-[#ffffff] cursor-pointer inline-block mb-2' style={{ display: 'inherit' }}>{office?.mobile_en}</a>
                                <iframe loading='lazy' title={office?.title_en} src={office?.location} width="100%" height="170" style={{ border: '0' }} referrerPolicy="no-referrer-when-downgrade"></iframe>
                                <p className='text-[#ffffff] text-opacity-70 text-sm  mt-4'>
                                    <StripTags policyText={office?.address_en} /> </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurOffices