import React, { useEffect } from 'react'
import Line from '../shapes/Line'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMember } from '../features/teams/teamSlice'
import { useState } from 'react'
import StripTags from '../utils/StripTags'
import moment from 'moment';

const SingleMember = () => {

    const { member } = useSelector((state) => state.team);
    const [team, setTeam] = useState(member.team);
    const [galleries, setGalleries] = useState([]);
    const [insights, setInsights] = useState([]);
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
    const fetchData = async () => {
        const memberData = await dispatch(getMember(slug));
        setTeam(memberData?.payload.team);
        setGalleries(memberData?.payload.galleries);
        setInsights(memberData?.payload.insights);
    };

    fetchData();

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, [dispatch, slug]);

    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }
    
  return (
    <>
        <div className='screen-1 mb-[20px] mt-[120px] md:mt-[140px]'>
            <div className='grid grid-cols-12'>
                <div className='flex flex-col md:flex-row md:items-center lg:items-start lg:flex-col mx-auto col-span-6'>
                    <div>
                        <img width="329" height="329" src={team?.image} className='w-[300px] h-full object-contain rounded-tr-[50px] rounded-bl-[50px] border border-primary-500' alt={team?.name_en}  />
                    </div>
                    <div className='flex flex-col p-3'>
                        <span className='text-lg 2xl:text-2xl text-primary-500 font-gotham-bold'>{team?.name_en}</span>
                        <span className='text-sm 2xl:text-lg text-primary-500  my-1'>{team?.role_en}</span>
                    </div>
                </div>
                <div className='h-full col-span-12 lg:col-span-6 mt-6 lg:mt-0'>
                    <p className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list leading-text'>
                       <StripTags policyText={team?.text_en} />
                    </p>
                </div>
            </div>
        </div>

        {/* LeaderShip */}
        {(insights?.length > 0 || galleries?.length > 0 )&& (
        <>
            <div className='screen-1 mb-6 lg:mb-16'>
                <div className='flex items-end justify-between py-3'>
                    <div className='w-3/4'>
                        <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>THOUGHT</span>
                        <div className='flex items-center space-x-6'>
                            <span className='uppercase  text-primary-500 2xl:text-3xl  text-xl  font-gotham-black'>LEADERSHIP</span>
                            <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
            <section className='screen-1'>
                <div className='grid lg:grid-cols-4 md:grid-cols-3  gap-4'>
                    {insights?.map((insight,id) => (
                            <div key={id} className={`group lg:col-span-${id == 0 ? 2 : 1}`}>

                            <Link to={`/insights/${insight?.slug}`}>
                                <div><img width="329" height="182" className='w-full h-full object-contain' src={insight?.image} alt={insight?.title_en}  /></div>
                                <div className='py-4 sm:px-5'>
                                    <p className='text-primary-500 font-gotham-bold uppercase 2xl:text-lg text-base group-hover:text-secondary-500 cursor-pointer duration-300 ease-in-out'>
                                        {insight?.title_en}
                                    </p>
                                    <p className=' 2xl:text-base text-sm'>{insight?.insight?.title_en}</p>
                                    <p className='text-xs text-primary-500 text-opacity-50  my-2'>{moment(insight?.date).format('LL')}</p>
                                    <p className='2xl:text-base text-sm'><StripTags policyText={truncate(insight?.text_en,200)} /></p>
                                    <div>
                                        <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500'>Learn More</Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                    </div>
            </section>
            {/* Galleries */}
            <section className='screen-1 my-12'>
                <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-4'>
                    {galleries?.map((gallery,id) => (
                        <>
                        {gallery?.isYoutube && (
                            <div key={id} className='sm:col-span-2'>
                                    <div>
                                    <iframe
                                        title={'Yellow Door Energy'}
                                        width='100%'
                                        height='300px'
                                        src={`https://www.youtube.com/embed/${gallery?.isYoutube.split('/').pop()}`}
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen
                                    ></iframe>
                                    </div>
                            </div>
                        )}
                        {gallery?.isYoutube == null && (
                            <>
                                <div>
                                    <div>
                                        <a href={gallery?.image}  data-lightbox={`image-${id}`} data-title={`${gallery?.title_en}`}>
                                            <img width="" height="" className='w-full h-full object-contain' src={gallery?.image} alt='Project Galleries'  />
                                            <div className='py-4 sm:px-5'>
                                                <p className='text-primary-500 font-gotham-bold uppercase text-sm 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                                    {gallery?.title_en}
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </>
                        )} 
                            
                        </>
                    ))}
                </div>
            </section>
       
        <div className='mb-16 mt-6'>
            <Contact />
        </div>

        <div className='my-16'>
            <OurOffices />
        </div>

        
    </>
  )
}

export default SingleMember