import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import StripTags from '../utils/StripTags'
const Testimonials = ({testimonials}) => {
  return (
    <>
        <div className='screen-1 py-3'>
            <div className='flex items-end justify-between'>
                <div >
                    <span className=' uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Customer</span>
                    <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                        <span className='uppercase  w-full text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>
                            Testimonials
                        </span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div >
                    <span className='md:text-base text-xs w-min md:w-full inline-block '>
                        <Link to={'/testimonials'} className='text-primary-500 hover:text-secondary-500'>View All Testimonials</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className='screen-1 py-8 md:py-24 swiper-container testimonialSwiper'>
        <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination,Autoplay]}
        autoplay={{
            delay: 4000,
            disableOnInteraction: false,
        }}
        className="testimonialSwiper"
      >
        {testimonials?.map((testimonial,id) => (
        <SwiperSlide>
            <div key={id} className='flex items-center justify-center'>
                <div className='w-full sm:w-[60%] 2xl:w-[47%] mx-auto relative'>
                    {/* <div className='absolute -top-10 -left-24 w-full h-full pos-1 hidden sm:block'><img src={TestimonialLeft} alt={testimonial?.name_en}/></div> */}
                    <div className='text-[#101010] font-gotham-bold text-sm md:text-base  2xl:text-xl'>
                        <StripTags policyText={testimonial?.text_en} />
                    </div>
                    <div className='flex flex-col  md:flex-row md:items-center justify-between mt-4'>
                        <div>
                            <p className='text-sm  2xl:text-xl text-primary-500 font-gotham-bold'>{testimonial?.name_en}</p>
                            <p className='2xl:text-base text-sm text-primary-500   md:w-[60%]'>
                                {testimonial?.role_en}
                            </p>
                        </div>
                        <div className='mt-4 md:mt-0'>
                            <img width="" height="" className='w-[200px] h-full' src={testimonial?.image} alt='Testimonial Yellow Door Energy'  />
                        </div>
                    </div>
                    {/* <div className='absolute -right-[4.75rem] top-[10rem] md:top-[7rem] w-full h-full hidden sm:block'><img className='float-right' src={TestimonialRight} alt=''/></div> */}
                </div>
            </div>
        </SwiperSlide>
        ))}
        
      </Swiper>
            
        </div>
    </>
  )
}

export default Testimonials