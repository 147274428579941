import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import homeService from "./homeService";
import { setServerError } from "../error500/error500Slice";


const initialState = {
  data: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAll = createAsyncThunk(
  "home/getAll",
  async (_, thunkApi) => {
    try {
      return await homeService.getAll();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 


      return thunkApi.rejectWithValue(message);
    }
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
  },
});

export const { reset } = homeSlice.actions;
export default homeSlice.reducer;