import React, { useEffect } from 'react'
import Line from '../shapes/Line'
import { getTeams } from '../features/teams/teamSlice'
import { Link } from 'react-router-dom'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { useDispatch, useSelector } from 'react-redux'
import SEO from '../components/SEO'
const OurTeam = () => {

    const { teams } = useSelector((state) => state.team);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTeams());
    },[dispatch])
   
  return (
    <>
        <h1 className='heading-title'>OUR SENIOR LEADERSHIP</h1>
        <SEO
            title="Our Senior Leadership | Team | Yellow Door Energy"
            description="Meet the visionary leadership and dedicated team driving innovation at Yellow Door Energy."
            url={window.location.href}
        />
        <div className='h-[130px] lg:h-[130px] flex flex-col bg-primary-500 w-full mt-[80px] md:mt-[110px]'>
            <div className='screen-1 w-full mt-auto pb-4' >
                <div className='w-3/4 pb-4'>
                    <span className='uppercase text-[#ffffff] lg::text-2xl md:text-xl text-lg leading-none'>Our</span>
                    <div className='flex items-center'>
                        <span className='uppercase text-[#ffffff] lg:text-2xl md:text-xl text-lg font-gotham-black w-full md:w-[86%] xl:w-[54%] 2xl:w-[38%]'>
                            Senior Leadership
                        </span>
                        <div className='space-y-1 w-full mt-9 md:mt-0'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='screen-1'>
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-[8rem] xl:gap-x-[10rem] 2xl:gap-x-[13rem] gap-y-12 my-16'>
            {teams.map((team, index) => (
                <Link to={`/teams/${team?.slug}`}>
                    <div key={index} className='flex flex-col cursor-pointer group'>
                        <div className='image-container-2 rounded-tr-[50px] rounded-bl-[50px] outline outline-primary-500 group-hover:outline-secondary-500 group-hover:outline-2 '>
                            <img  src={team?.image} className='' alt={team?.name_en}  />
                        </div>
                        <div className='flex flex-col p-3'>
                            <span className='text-xl text-primary-500 font-gotham-bold group-hover:text-secondary-500 duration-200 ease-in-out'>{team?.name_en}</span>
                            <span className='text-sm text-primary-500 my-1'>{team?.role_en}</span>
                            <Link to={`/teams/${team?.slug}`} className='text-base text-primary-500  hover:text-secondary-500 '>View Bio</Link>
                        </div>
                    </div>
                </Link>
            ))}
            </div>    
        </div>

        <div className='my-16'>
            <Contact />
        </div>

        <div className='my-16'>
            <OurOffices />
        </div>
    </>
  )
}

export default OurTeam