import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/links`;

const getCareer = async () => {
    const response = await axios.get(API_URL + '/career');
    return response.data;
};

const getPolicy = async () => {
    const response = await axios.get(API_URL + '/policy');
    return response.data;
};

const externalsService = {
    getCareer,
    getPolicy,
};

export default externalsService;