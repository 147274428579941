import React, { useEffect } from 'react'
import TestimonialBanner from '../assets/images/testimonial.png'
import { useDispatch, useSelector } from "react-redux";
import { getTestimonials, getAwards } from "../features/testimonials/testimonialSlice";
import Award from '../assets/images/award.png'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import Line from '../shapes/Line'
import StripTags from '../utils/StripTags';
import { useLocation } from 'react-router-dom';
import { getBanner } from '../features/banners/bannerSlice';
import SEO from '../components/SEO';
const Testimonial = () => {

    const { banner } = useSelector((state) => state.banner);
    const { testimonials,awards } = useSelector((state) => state.testimonial);
    const dispatch = useDispatch();

    const location = useLocation();
    const slug = location?.pathname.split('/')[1];
  
    useEffect(() => {
        dispatch(getBanner(slug));
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    },[dispatch])


    useEffect(() => {
        dispatch(getTestimonials());
        dispatch(getAwards());

     
    },[dispatch])

  return (
    <>
        <h1 className='heading-title'>TESTIMONIALS AND AWARDS</h1>
        <SEO
            title="Testimonials And Awards | Yellow Door Energy"
            description="We are grateful to have received numerous awards and customer testimonials. They motivate us to continue helping businesses decarbonize and become more resilient."
            url={window.location.href}
        />
        <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[344px]'>
                <img width="1600" height="800" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[36%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[76%] w-full '>
                </div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                    <div className='lg:ml-auto lg:w-[70%] lg:pl-[63px]'>
                        <div className='w-full lg:w-[45%]'>
                            <span className='uppercase text-[#ffffff] text-2xl lg:text-xl font-gotham-bold'>{banner?.title_en}</span>
                        </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff] lg:text-base  2xl:text-lg my-3 inline-block '>
                              <StripTags policyText={banner?.text_en} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='screen-1 my-16'>
            {testimonials?.map((testimonial,id) => (
                <div key={id} className='grid md:grid-cols-3 items-center lg:gap-24 mb-12'>
                    <div className='col-span-3 md:col-span-1'><img width="482" height="202" className='w-full h-full object-contain' src={testimonial?.image} alt={testimonial?.name_en}  /></div>
                    <div className='col-span-3 lg:col-span-2 mt-2 lg:mt-0'>
                        <p className='text-[#101010] text-sm md:text-base 2xl:text-lg text-start'>
                            <StripTags policyText={testimonial?.text_en} />
                        </p>
                        <div className='mt-1'>
                            <p className='text-sm md:text-base 2xl:text-lg text-primary-500 font-gotham-bold text-start'>{testimonial?.name_en}</p>
                            <p className='2xl:text-base text-sm text-primary-500   md:w-[40%] text-start'>
                                {testimonial?.role_en}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>

        <div className='screen-1 my-16'>
            {awards.length > 0 && (
                <>
                <div >
                    <span className=' uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Our</span>
                    <div className='flex flex-col md:items-center md:flex-row md:space-x-6'>
                        <span className='uppercase text-primary-500 2xl:text-3xl  text-xl  font-gotham-black'>
                            AWARDS
                        </span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div className='grid lg:grid-cols-3 gap-9 my-16 md:grid-cols-2'>
                    {awards?.map((award,id) => (
                        <div key={id}>
                            <img width="154" height="154" className='mx-auto w-[150px] object-contain mb-6' src={award?.icon} alt={award.title_en}  />
                            <div className='text-center text-primary-500 font-gotham-bold uppercase text-sm'>{award.title_en}</div>
                            <div className='text-center text-sm'>
                                <StripTags policyText={award.text_en} />
                            </div>
                        </div>
                    ))}
                </div>
            </>
            )}
            
        </div>

        <div className='my-16'>
            <Contact />
        </div>

        <div className='my-16'>
            <OurOffices />
        </div>
    </>

  )
}

export default Testimonial