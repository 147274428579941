import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/home`;


const getAll = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const homeService = {
  getAll,
};

export default homeService;