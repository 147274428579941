import { createContext, useState } from "react";

export const PopUpContext = createContext({});
export const PopupProvider = ({ children }) => {

const [popUp, setPopUp] = useState(false);

return (
    <PopUpContext.Provider
      value={{
        popUp,
        setPopUp,
      }}
    >
      {children}
    </PopUpContext.Provider>
    )
}