import React, { useEffect } from 'react'
import Benefits from '../components/Benefits'
import Line from '../shapes/Line'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMarket } from '../features/markets/marketSlice'
import { useState } from 'react'
import moment from 'moment';
import StripTags from '../utils/StripTags'
import SEO from '../components/SEO'

const Market = () => {

    const [insights, setInsights] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const [solars, setSolars] = useState([]);
    const [projects, setProjects] = useState([]);
    const [teams, setTeams] = useState([]);
    const [office, setOffice] = useState({});
    const [banner, setBanner] = useState({});
    const [seoTitle,setSEOTitle] = useState('');
    const [seoDescription,setSEODescription] = useState('');
    const [headerTitle,setHeaderTitle] = useState('');
    const [linkHrefLang, setLinkHrefLang] = useState(0);
    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }

      useEffect(() => {
        const fetchData = async () => {
           const data = await dispatch(getMarket(slug))
           setInsights(data?.payload?.insights)
           setBenefits(data?.payload?.benefits)
           setSolars(data?.payload?.solutions)
           setProjects(data?.payload?.projects)
           setTeams(data?.payload?.teams)
           setOffice(data?.payload?.office)
           setBanner(data?.payload?.banner)

           if(data?.error) {
            navigate('/404')
        }
        }
        fetchData()
       
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        if(window.location.pathname == '/markets/jordan') {
            setSEOTitle('BOOT Arrangement in Jordan | Economic Modernization Vision | Yellow Door Energy');
            setSEODescription("Empowering Jordan with 80 MW of solar wheeling projects. Partnering with businesses and government, we drive clean electricity and contribute to Jordan's Economic Modernization Vision.");
            setHeaderTitle('Build-Own-Operate-Transfer');
            setLinkHrefLang(2);
        } else if (window.location.pathname == '/markets/uae') {
            setSEOTitle('Solar Leasing In UAE | COP28 | Net Zero by 2050 | Yellow Door Energy');
            setSEODescription("COP28 UAE commits to triple renewable energy and transition away from fossil fuels. Support these targets with our solar leases! Committed to UAE's Clean Energy Strategy, Net Zero 2050, and COP28");
            setHeaderTitle('Solar Leasing');
            setLinkHrefLang(1);
        } else if (window.location.pathname == '/markets/south-africa') {
            setSEOTitle('PPAs for Solar and BESS | Secure Your Energy Supply | Yellow Door Energy');
            setSEODescription("Our Solar PPAs offer a secure, reliable, and cost-effective electricity supply with solar PV, BESS, and more. Ensure a clean and resilient power supply for your business.");
            setHeaderTitle('Solar Power Purchase Agreement');
            setLinkHrefLang(3);
        } else if (window.location.pathname == '/markets/bahrain') {
            setSEOTitle('Solar PPA in Bahrain | Net Zero by 2060 | Yellow Door Energy');
            setSEODescription("Our solar power purchase agreement (PPA) helps commercial and industrial businesses reduce energy costs. Save money, pay 0 BHD upfront, lower carbon emissions, and contribute to Bahrain's Net Zero by 2060 target. Own the solar plant after the term.");
            setHeaderTitle('Solar PPA');
            setLinkHrefLang(5);
        } else if (window.location.pathname == '/markets/oman') {
            setSEOTitle('Solar Power Purchase Agreement in Oman | Net Zero By 2050 | Yellow Door Energy');
            setSEODescription("Our Solar PPAs cut costs, enhance resilience, and offer clean energy. Save significantly with no upfront payment, managing long-term electricity costs. Let's achieve Oman's Net Zero by 2050 target together!");
            setHeaderTitle('Solar Power Purchase Agreement (PPA)');
            setLinkHrefLang(6);
        } else if (window.location.pathname == '/markets/saudi-arabia') {
            setSEOTitle('Solar Leasing In KSA | Saudi Green Initiative | Yellow Door Energy');
            setSEODescription("We support businesses in achieving sustainability objectives, aligned with Saudi Green Initiative & Vision 2030. Contact us for on-grid and off-grid solar solutions.");
            setHeaderTitle('Solar Leasing');
            setLinkHrefLang(4);
        } 

      },[dispatch,slug])

  return (
    <>
        <h1 className='heading-title'>{headerTitle}</h1>
        <SEO
            title={seoTitle}
            description={seoDescription}
            href={linkHrefLang}
            url={window.location.href}
        />
        <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[344px]'>
                <img width="1600" height="800" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[37%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[76%] w-full '>
                </div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                    <div className='lg:ml-auto lg:w-[70%] lg:pl-[30px]'>
                        <div className='w-full'>
                            <span className='uppercase text-[#ffffff] text-2xl lg:text-xl font-gotham-bold'>{banner?.title_en}</span>
                        </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff] lg:text-base  2xl:text-lg my-3 inline-block '>
                              <StripTags policyText={banner?.text_en} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {solars?.map((solar,id) => (
        <div className='screen-1 mt-6'>
            <div className='grid md:grid-cols-3 mb-12'>
                <div className='w-full flex flex-col col-span-1'>
                    <span className='uppercase text-primary-500 2xl:text-3xl text-xl leading-none'>
                        OUR SOLUTION
                    </span>
                    <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black'>
                        {solar?.title_en}
                    </span>
                    <div className='space-y-1 mt-2'>
                        <Line width={60} />
                        <Line width={120} />
                    </div>
                </div>
                
                    <div key={id} className='mt-1 col-span-2'>
                        <p>
                            <span className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'><StripTags policyText={solar?.text_en} /></span> 
                        </p>
                    </div>
                </div>
            </div>

        ))}
               
        {/* Benefits */}
        <div className='mb-6'>
            <div className='screen-1'>
                <div className='flex flex-col lg:flex-row lg:justify-between py-3 '>
                    <div>
                        <span className='uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Your</span>
                        <div className='flex flex-col'>
                            <span className='uppercase  w-full text-primary-500 2xl:text-3xl text-xl font-gotham-black'>Benefits</span>
                            <div className='space-y-1 w-full mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-2 gap-12 w-full mt-12 lg:mt-0 lg:w-[66.5%]'>
                        {benefits?.map(({text_en}, id) => (
                            <Benefits key={id} text={text_en} />
                        ))}
                    </div>
                </div>
            </div>
        </div>   


        {/* Stories */}
        <div className='my-3'>
        <div className='screen-1'>
            <div className='flex items-end justify-between py-3'>
                <div>
                    <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Success</span>
                    <div className='flex flex-col md:flex-row items-center md:space-x-6'>
                        <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Stories</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div >
                    <span className='md:text-base text-xs w-full inline-block'>
                        <Link to={'/projects'} className='text-primary-500 hover:text-secondary-500'>View All Projects</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className='screen-1 pt-14 pb-4'>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-4'>
            {projects?.map((project,id) => (
                <>
                {project?.isYoutube && (
                    <div key={id} className='sm:col-span-2'>
                            <div>
                            <iframe
                                title={project?.title_en}
                                width='100%'
                                height='300px'
                                src={`https://www.youtube.com/embed/${project?.isYoutube.split('/').pop()}`}
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            ></iframe>
                            </div>
                    </div>
                )}
                {project?.isYoutube == null && (
                    <>
                        <div>
                            <div>
                                <Link to={`/projects/${project?.slug}`}>
                                <div className='image-container'>
                                    <img  className='' src={project?.image} alt='Insight 1'  />
                                </div>
                                    <div className='py-4 px-5'>
                                        <p className='text-primary-500 hover:text-secondary-500 duration-300 font-gotham-bold uppercase text-base 2xl:text-lg'>
                                            {project?.title_en}
                                        </p>
                                        <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.mwp_en}</p>
                                        <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.location_en}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>
                )} 
                    
                </>
            ))}
        </div>
        </div>
        </div>
             

         {/* Insights */}
        <div>
            <div className='screen-1'>
                <div className='flex items-end justify-between py-3'>
                    <div>
                        <span className='uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Latest</span>
                        <div className='flex flex-col md:flex-row items-center md:space-x-6'>
                            <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Insights</span>
                            <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                    <div >
                        <span className='md:text-base text-xs  w-full inline-block'>
                            <Link to={'/insights'} className='text-primary-500 hover:text-secondary-500'>View All Insights</Link>
                        </span>
                    </div>
                </div>
            </div>
            <div className='bg-[#023A6C] bg-opacity-10'>
            <div className='screen-1 pt-14 pb-4'>
                <div className='grid lg:grid-cols-4 md:grid-cols-3  gap-4'>
                    {insights?.map((insight,id) => (
                        <div key={id} className={`group lg:col-span-${id == 0 ? 2 : 1}`}>

                            <Link to={`/insights/${insight?.slug}`}>
                                <div className='image-container'><img  className='' src={insight?.image} alt='Insight 1'  /></div>
                                <div className='py-4 sm:px-5'>
                                    <p className='text-primary-500 font-gotham-bold uppercase 2xl:text-lg text-base group-hover:text-secondary-500 cursor-pointer duration-300 ease-in-out'>
                                        {insight?.title_en}
                                    </p>
                                    <p className=' 2xl:text-base text-sm'>{insight?.insight?.title_en}</p>
                                    <p className='text-xs text-primary-500 text-opacity-50  my-2'>{moment(insight?.date).format('LL')}</p>
                                    <p className='2xl:text-base text-sm'><StripTags policyText={truncate(insight?.text_en,200)} /></p>
                                    <div>
                                        <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500 '>Learn More</Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </div>

        
        <div className='my-12'>
            <div className='min-h-[700px] sm:min-h-[700px] lg:min-h-[350px] flex flex-col bg-primary-500 w-full'>
                <div className='screen-1 w-full mt-auto lg:mt-8'>
                    <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-8 mb-[40px]'>
                        <div className='lg:col-span-2 xl:col-span-1 '>
                            <p className='font-gotham-black uppercase text-secondary-500  text-xl lg:text-2xl xl:text-3xl'>{office?.title_en}</p>
                            <a href={`tel:${office?.mobile}`}>
                                <p className='font-gotham-bold text-lg xl:text-xl  text-[#ffffff] mb-2 cursor-pointer'>{office?.mobile_en}</p>
                            </a>
                            <p className='text-[#ffffff] text-opacity-70 text-base mt-1'><StripTags policyText={office?.address_en} /></p>
                        </div>
                        <div className='h-[170px] w-full mx-w-full xl:pl-[11px] xl:pr-[11px]'><iframe src={office?.location} width='100%'  height="100%" style={{border:'0'}} allowfullscreen=""  referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                        <div className='group md:ml-4'>
                            <img width="398" height="175" className='w-full xl:w-[350px] h-[170px] object-cover' src={office?.image} alt={office?.title_en}  />
                            <div className='text-[#ffffff] mt-1 lg:opacity-0 lg:group-hover:opacity-100 duration-500'>
                                <StripTags policyText={office?.description_en} />
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </div>
            </div>


            <div >
            <div className='screen-1'>
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-[8rem] xl:gap-x-[10rem] 2xl:gap-x-[13rem] gap-y-12'>
            {teams.filter((x) => x?.markets?.some((market) => market.slug == slug))
            .map((team, index) => (
                <Link to={`/teams/${team?.slug}`}>
                    <div key={index} className='flex flex-col cursor-pointer group '>
                            <div className='image-container-2 rounded-tr-[50px] rounded-bl-[50px] outline outline-primary-500 group-hover:outline-secondary-500 group-hover:outline-2'>
                                <img src={team?.image} className='' alt={team?.name_en}  />
                            </div>
                        <div className='flex flex-col p-3'>
                            <span className='text-xl text-primary-500 font-gotham-bold group-hover:text-secondary-500 duration-200 ease-in-out'>{team?.name_en}</span>
                            <span className='text-sm text-primary-500 my-1'>{team?.role_en}</span>
                            <Link to={`/teams/${team?.slug}`} className='text-base text-primary-500  hover:text-secondary-500 '>View Bio</Link>
                        </div>
                    </div>
                </Link>
            ))}
            </div>    
        </div>

        </div>


            <div className='mb-16 mt-8'>
                <Contact />
            </div>

            <div className='my-16'>
                <OurOffices />
            </div>
    </>
  )
}

export default Market