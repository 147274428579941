import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/sections`;

const getFooter = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

const fixedService = {
    getFooter,
};

export default fixedService;