import axios from "axios";
import { useEffect, useState } from "react"
import SEO from "../components/SEO";
import config from "../config";
import StripTags from "../utils/StripTags";


function PrivacyPolicy() {

    const [privacy,setPrivacy] = useState({});

    useEffect(() => {
        try {
            const fetchData = async () => {
                const res = await axios.get(`${config.apiUrl}/api/privacy-policy`)
                setPrivacy(res.data);
            }
            fetchData();
        } catch (error) {
            console.log(error);
        }
        
    },[])

    console.log('Privacy', privacy);

  return (
    <>
    <div className="screen-1 my-[80px] md:mt-[110px]">
        <SEO
            title="Privacy Policy | Yellow Door Energy"
            description="We are a values-driven company and our values are Sustainability, Passion, Integrity, Collaboration and Agility. Take a look at what this means for our business."
            url={window.location.href}
        />
        <h1 className="text-2xl">Privacy Policy</h1>
        <p className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list leading-text policy-text'>
            <StripTags policyText={privacy?.text} />
        </p>
    </div>  
    </>
  )
}

export default PrivacyPolicy