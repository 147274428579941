import React, { useEffect } from 'react'
import OurOffices from '../components/OurOffices'
import Team from '../assets/images/homescreen/team.webp'
import { getBanner } from '../features/banners/bannerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import StripTags from '../utils/StripTags';
import { getCareer } from '../features/externals/externalSlice';
import SEO from '../components/SEO';

const Careers = () => {

  const dispatch = useDispatch();
  const { banner } = useSelector((state) => state.banner);
  const { career } = useSelector((state) => state.external);
  const location = useLocation();
  const slug = location?.pathname.split('/')[1];

  useEffect(() => {
      dispatch(getBanner(slug));
      dispatch(getCareer())
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  },[dispatch])

 

  return (
    <>
        <h1 className='heading-title'>WE'RE HIRING!</h1>
        <SEO
            title="Careers | Realize Your Potential | Yellow Door Energy"
            description="As a fast-growing company, we are always looking for new team members. We are positively impacting the environment and society, and you can, too!"
            url={window.location.href}
        />
        <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[450px]'>
                <img width="" height="" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[45%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[70%] w-full '></div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                  <div className='lg:ml-auto lg:w-[55%]'>
                    <div >
                        <div className='w-[70%]'>
                            <span className=' text-[#ffffff] md:text-2xl text-xl font-gotham-bold'>{banner?.title_en}</span>
                        </div>
                    </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff]   lg:text-base  2xl:text-lg r my-3 inline-block'>
                               <StripTags policyText={banner?.text_en} />
                            </span>
                        </div>
                        <div>
                            <button className='py-1 px-6 mt-4 r font-gotham-medium uppercase bg-secondary-500 border border-transparenthover:border-secondary-500 hover:bg-primary-500 hover:text-secondary-500 duration-300 ease-in-out
                                 rounded-tl-[10px] rounded-br-[10px] rounded-tr-[4px] rounded-bl-[4px] text-primary-500 text-xs xl:text-base'>
                                   <Link to={career?.link} target="_blank" rel="noopener noreferrer">
                                        {career?.title_en}
                                   </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='my-16'>
            <OurOffices />
        </div>
  
    </>
  )
}

export default Careers