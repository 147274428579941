import React from 'react'
import banner from '../assets/images/homescreen/how-it-works.png'
import Line from '../shapes/Line'
const HowWorks = () => {
  return (
    <>
        <div className='bg-[#023A6C] bg-opacity-10'>
            <div className='screen-1  py-14'>
                <div className='flex lg:flex-row flex-col py-3'>
                    <div>
                        <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>how</span>
                        <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                            <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>It Works</span>
                            <div className='space-y-1 lg:mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                    <div className='sm:mx-auto'>
                        <img width="350" height="100%" className='w-[200px] sm:w-[300px] 2xl:w-[350px] mt-6 lg:mt-0' src={banner} alt='How Yellow Energy Works'  />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default HowWorks