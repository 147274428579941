import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'

const Instagram = () => {

  return (
    <>
        <div className='screen-1'>
            <div className='flex items-end justify-between py-3'>
                <div >
                    <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Our</span>
                    <div className='flex flex-col md:flex-row items-center md:space-x-6'>
                        <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Instagram</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div >
                    <span className='md:text-base text-xs w-full inline-block'>
                        <Link to={'https://www.instagram.com/yellowdoorenergy/'} target="_blank" className='text-primary-500 hover:text-secondary-500'>View All</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className='screen-1 pt-14'>
                <ul class="juicer-feed" data-feed-id="yellowdoorenergy" data-per="8"></ul>          
        </div>
    </>
  )
}

export default Instagram