import React, { useEffect } from 'react'
import Benefits from '../components/Benefits'
import Hero from '../components/Hero'
import Insight from '../components/Insight'
import ProductApplication from '../components/ProductApplication'
import Stories from '../components/Stories'
import SustainableEnergy from '../components/SustainableEnergy'
import Testimonials from '../components/Testimonials'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'
import ShareHolders from '../components/ShareHolders'
import SustainabilityJourney from '../components/SustainabilityJourney'
import OurOffices from '../components/OurOffices'
import Instagram from '../components/Instagram'
import HowWorks from '../components/HowWorks'
import Contact from '../components/Contact'
import { useDispatch } from 'react-redux'
import { getAll } from '../features/home/homeSlice'
import { useState } from 'react'
import StripTags from '../utils/StripTags'
import SEO from '../components/SEO'


const Home = () => {

    const [banners,setBanners] = useState([])
    const [counters,setCounters] = useState([])
    const [insights,setInsights] = useState([])
    const [projects,setProjects] = useState([])
    const [testimonials,setTestimonials] = useState([])
    const [shareholders,setShareholders] = useState([])
    const [benefits,setBenefits] = useState([])
    const [stories,setStories] = useState([])
    const [journey,setJourney] = useState({})
    const [solution,setSolution] = useState({})
    const [products, setProducts] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const data = await dispatch(getAll())
            setBanners(data?.payload?.banners)
            setCounters(data?.payload?.counters)
            setInsights(data?.payload?.insights)
            setProjects(data?.payload?.projects)
            setTestimonials(data?.payload?.testimonials)
            setShareholders(data?.payload?.shareholders)
            setBenefits(data?.payload?.benefits)
            setStories(data?.payload?.stories)
            setJourney(data?.payload?.journey)
            setSolution(data?.payload?.solutions)
            setProducts(data?.payload?.products)
        }

        fetchData()

     
    },[dispatch])

  return (
    <>
    <h1 className='heading-title'>Sustainable Energy</h1>
    <SEO
        title="Leading Solar Company in Dubai, UAE | Yellow Door Energy"
        description="Yellow Door Energy is the leading sustainable solar energy company for businesses, serving commercial and industrial customers in the Middle East, Africa, and beyond."
        url={window.location.href}
    />
    <main>

        <Hero counters={counters} banners={banners} />
        
        <div className='my-16'>
            <SustainableEnergy solution={solution} />
        </div>

        {insights?.length > 0 && (
            <div className='my-16'>
                <Insight insights={insights} />
            </div>
        )}
        
        <div className='mt-16'>
            <Stories projects={projects} />
        </div>

        <div className='my-16'>
            <ProductApplication products={products} />
        </div>

        <div className='my-16'>
            <HowWorks />
        </div>

        <div className='mt-16'>
            <div className='screen-1 6'>
                <div className='flex flex-col lg:flex-row lg:justify-between  py-3 '>
                    <div >
                        <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Your</span>
                        <div className='flex flex-col'>
                            <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Benefits</span>
                            <div className='space-y-1 w-full mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-2 gap-12 w-full mt-12 lg:mt-0 lg:w-[66.5%]'>
                        {benefits?.map(({text_en}, id) => (
                            <Benefits key={id} text={text_en} />
                        ))}
                    </div>
                </div>
            </div>
        </div>


        <div>
            <Testimonials testimonials={testimonials} />
        </div>

        <div className='mb-16'>
            <div className='screen-1 py-3'>
                <div className='flex items-end justify-between'>
                    <div >
                        <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none leading-none'>Our</span>
                        <div className='flex flex-col md:flex-row md:items-center md:space-x-4'>
                            <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>
                                Story
                            </span>
                            <div className='space-y-1 mt-2 md:mt-0 2xl:mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                    <div >
                        <span className='md:text-base text-xs w-full inline-block '>
                            <Link to={'/our-story'} className='text-primary-500 hover:text-secondary-500'>Learn More</Link>
                        </span>
                    </div>
                </div>
            </div>
            <div className='bg-primary-500 h-full'>
                <div className='screen-1'>
                    <div className='flex flex-col xl:flex-row items-center space-y-8 py-10 md:py-0 md:space-y-0 h-full md:h-[300px]'>
                        <div className='flex flex-col sm:flex-row lg:items-center justify-center xl:justify-end w-full gap-5 lg:gap-0'>
                            <img width="" height="" className='w-[60px] md:w-[8%] xl:w-[13%] h-full object-contain block' src={stories && stories[0] ? stories[0].icon : ''} alt='Mission' />
                            <div className='flex-col flex md:mx-auto lg:mx-8 text-[#ffffff]'>
                                <span className=' uppercase text-[#ffffff] text-xl md:text-2xl'>{stories[0]?.title_en}</span>
                                <span className='uppercase  text-[#ffffff] text-xl md:text-2xl font-gotham-black'>{stories[0]?.subtitle_en}</span>
                            </div>
                            <div className='ml-auto lg:ml-0 sm:w-3/4'>
                                <div className='text-[#ffffff] font-gotham-medium text-base md:text-lg'>
                                    <StripTags policyText={stories[0]?.text_en} />
                                </div>
                            </div>
                        </div> 

                        <div className='flex flex-col sm:flex-row lg:items-center justify-center xl:justify-end w-full gap-5 lg:ml-12'>
                            <img width="" height="" className='w-[60px] md:w-[8%] xl:w-[13%] h-full object-contain block' src={stories && stories[1] ? stories[1].icon : ''} alt='Mission' />
                            <div className='flex-col flex md:mx-auto lg:mx-8 text-[#ffffff]'>
                                <span className=' uppercase text-[#ffffff] text-xl md:text-2xl'>{stories[1]?.title_en}</span>
                                <span className='uppercase  text-[#ffffff] text-xl md:text-2xl font-gotham-black'>{stories[1]?.subtitle_en}</span>
                            </div>
                            <div className='ml-auto lg:ml-0 sm:w-3/4'>
                                <div className='text-[#ffffff] font-gotham-medium text-base md:text-lg'>
                                    <StripTags policyText={stories[1]?.text_en} />
                                </div>
                            </div>
                        </div> 
                    </div> 
                </div>
            </div>   
        </div>  


        <div className='mt-16'>
            <ShareHolders shareholders={shareholders} />
        </div>

        <div>
            <SustainabilityJourney title={true} name={journey?.name_en} role={journey?.role_en} image={journey?.image} text={journey?.text_en} />
        </div>

        <div className='mb-16'>
            <Instagram />
        </div>

        <div className='my-16'>
            <Contact />
        </div>

        <div className='my-16'>
            <OurOffices />
        </div>
     </main>
    </>
  )
}

export default Home