import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'
import Insight1 from '../assets/images/sustain/Picture1.png'
import StripTags from '../utils/StripTags'
const SustainabilityJourney = ({title,image,name,role,text}) => {
  return (
    <>
    {title && (
        <div className='screen-1 py-3'>
        <div className='flex items-end justify-between'>
            <div >
                <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none '>TOWARDS NET ZERO: </span>
                <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                    <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>
                        OUR SUSTAINABILITY JOURNEY
                    </span>
                    <div className='space-y-1 mt-2 md:mt-0 2xl:mt-2'>
                        <Line width={60} />
                        <Line width={120} />
                    </div>
                </div>
            </div>
            <div >
                <span className='md:text-base text-xs w-full inline-block '>
                    <Link to={'/sustainability'} className='text-primary-500 hover:text-secondary-500'>Learn More</Link>
                </span>
            </div>
        </div>
    </div> 
    )}
        
        <div className='screen-1 py-12'>
            <div className='flex flex-col space-y-8 lg:space-y-0 lg:flex-row items-center justify-center'>
                <div className='inline-block bg-secondary-500 rounded-full'>
                        <img width="209" height="209" className='w-[160px] h-[160px] lg:w-[180px] lg:h-[180px] rounded-full' src={image ? image : Insight1} alt={name}  />
                </div>
                <div className='w-full lg:w-[60%] mx-auto relative'>
                    <p className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list lg:pad-30'>
                        <StripTags policyText={text} />
                    </p>
                    <div className='flex flex-col items-end mt-6'>
                        <p className='text-sm md:text-base  2xl:text-xl text-primary-500 font-gotham-bold'>{name}</p>
                        <p className='2xl:text-base text-sm text-end lg:w-1/2 text-primary-500 '>
                            {role}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SustainabilityJourney