import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import teamService from "./teamService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  teams: [],
  member: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getTeams = createAsyncThunk(
  "teams/getAll",
  async (_, thunkApi) => {
    try {
      return await teamService.getTeams();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503)) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getMember = createAsyncThunk(
    "teams/getMember",
    async (slug, thunkApi) => {
      try {
        return await teamService.getMember(slug);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
);

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.teams = action.payload;
      })
      .addCase(getMember.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.member = action.payload;
      })
  },
});

export const { reset } = teamSlice.actions;
export default teamSlice.reducer;