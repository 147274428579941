import React, { useEffect } from 'react'
import logo from '../assets/images/logo-white.png'
import maintenanceLogo from '../assets/images/maintenance.png'

const Maintenance = () => {


    return (
        <>
            <div className='maintenance-container flex flex-col items-center bg-[#023a6d]'>
                <div className='flex justify-center'>
                    <div className='flex'>
                        <img src={logo} width={250} height={100} alt='Yellow Door Energy' className='maintenance-logo' />
                        <img className='ms-5 maintenance-logo-2' src={maintenanceLogo} width={120} height={100} alt='Yellow Door Energy' />
                    </div>
                </div>
                <div className='maintenance-text-container'>
                    <div className='maintenance-text font-gotham font-bold '>
                        <p className="text-[40px] mt-8">Hello!</p>
                    <div className='text-[18px]'>
                        <p className='mt-10'>
                            Thank you for visiting Yellow Door Energy’s website. We’re just doing some routine maintenance and will be back shortly.
                        </p>
                        <p className='mt-10'>Meanwhile, please visit our social media pages for the latest company updates!</p>
                        <a className='mt-10 block underline break-words	' target="_blank" href="https://www.linkedin.com/company/yellowdoorenergy"> 
                            https://www.linkedin.com/company/yellowdoorenergy
                        </a>
                            <a href="https://www.instagram.com/yellowdoorenergy" target="_blank" className='underline break-words'>
                            https://www.instagram.com/yellowdoorenergy
                        </a>
                        <p className='mt-10'>You can also get in touch via <a href="mailto:gosolar@yellowdoorenergy.com ">
                            gosolar@yellowdoorenergy.com </a>or send us a message on WhatsApp: +971 52 887 2587
                        </p>
                        <p className='mt-10'>Thank you for your patience and have a sunny day!</p>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Maintenance