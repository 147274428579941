import axios from "axios";
import config from "../../config";


const API_URL = `${config.apiUrl}/api/social-media`;


const getSocial = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const socialService = {
  getSocial,
};

export default socialService;