import React from 'react'
import Line from '../shapes/Line'
import checkbox from '../assets/images/checkbox.svg'
import StripTags from '../utils/StripTags'
const Benefits = ({text}) => {
  return (
    <>
    {text && (
        <div className='flex space-x-2'>
            <div>
                <img width="100%" height="100%" className='w-full object-contain' src={checkbox} alt=''  />
            </div>
            <span className='text-base md:text-lg w-3/4 text-primary-500 font-gotham-medium' >
                <StripTags policyText={text} />
            </span>
        </div>
    )}
</>
  )
}

export default Benefits