import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'
import StripTags from '../utils/StripTags'
import moment from 'moment';

const Insight = ({insights}) => {
    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }
  return (
    <>
        <div className='screen-1 mt-16'>
            <div className='flex items-end justify-between py-3 relative z-20'>
                <div>
                    <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Latest</span>
                    <div className='flex flex-col md:flex-row items-center md:space-x-6'>
                        <span className='uppercase max-w-[490px] min-w-[180px] text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Solar Insights</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div>
                    <span className='md:text-base text-xs w-full inline-block'>
                        <Link to={'/insights'} className='text-primary-500 hover:text-secondary-500'>View All Insights</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className='bg-[#023A6C] bg-opacity-10'>
        <div className='screen-1 pt-14 pb-4'>
        <div className='grid lg:grid-cols-4 md:grid-cols-3  gap-4'>
            {insights?.map((insight,id) => (
                    <div key={id} className={`group lg:col-span-${id == 0 ? 2 : 1}`}>
                    <Link to={`/insights/${insight?.slug}`}>
                        <div className='image-container'><img  className='' src={insight?.image} alt={insight?.title_en}  /></div>
                        <div className='py-4 sm:px-5'>
                            <p className='text-primary-500 font-gotham-bold uppercase 2xl:text-lg text-base group-hover:text-secondary-500 cursor-pointer duration-300 ease-in-out'>
                                {insight?.title_en}
                            </p>
                            <p className=' 2xl:text-base text-sm'>{insight?.insight?.title_en}</p>
                            <p className='text-xs text-primary-500 text-opacity-50  my-2'>{moment(insight?.date).format('LL')}</p>
                            <p className='2xl:text-base text-sm'><StripTags policyText={truncate(insight?.text_en,200)} /></p>
                            <div>
                                <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500'>Learn More</Link>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
            </div>
        </div>
    </div>
    </>
  )     
}

export default Insight