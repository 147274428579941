import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import marketService from "./marketService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  markets: [],
  market: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getList = createAsyncThunk(
  "markets/getList",
  async (_, thunkApi) => {
    try {
      return await marketService.getList();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getMarket = createAsyncThunk(
  "markets/getMarket",
  async (slug, thunkApi) => {
    try {
      return await marketService.getMarket(slug);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 500) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.markets = action.payload;
      })
      .addCase(getMarket.pending, (state) => {
        state.isLoading = true; 
        state.isSuccess = false; 
      })
      .addCase(getMarket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.market = action.payload;
      })
  },
});

export const { reset } = marketSlice.actions;
export default marketSlice.reducer;