import React, { useEffect, useState } from 'react'
import Line from '../shapes/Line'
import Search from '../assets/images/search.svg'
import Filter from '../assets/images/filter.svg'
import { Link } from 'react-router-dom'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTags, getInsightByAlpha, getInsightByTags, getInsights, getInsightsBySearch, getOldestInsight } from '../features/insights/insightSlice'
import StripTags from '../utils/StripTags'
import moment from 'moment';
import LoadingComponent from '../components/LoadingComponent'
import SEO from '../components/SEO'

const Insights = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [activeFilter, setActiveFilter] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { insights ,tags , isLoading } = useSelector((state) => state.insight);
    const dispatch = useDispatch();

    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }

    const handleFitler = (number) => {
        setActiveFilter(number);
    } 


    const handleTabClick = (tagId) => {
        
        if (tagId === 0) {
          dispatch(getInsights(1));
          setActiveTab(0);
        } else {
          setActiveTab(tagId);
        }

        setCurrentPage(1); // reset the page 
    };

    const handleCurrentPage = (page) => {
        setCurrentPage(parseInt(page.split('page=')[1])); 
    }
  
    useEffect(() => {
        const fetchData = async () => {
            if (activeTab == 0) {
                dispatch(getInsights(currentPage));
            }
            dispatch(getAllTags());
        };

        fetchData();

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
     
    }, [dispatch,currentPage]);

    useEffect(() => {
        const fetchFilteredInsights = async () => {
        if (activeTab === 0) {
            // Fetch all insights
            dispatch(getInsights(currentPage));
        } else {
            // Fetch Insight by Tag
            dispatch(getInsightByTags({ id: activeTab, page: currentPage }));
        }
        };

        fetchFilteredInsights();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }, [activeTab,currentPage]);

    useEffect(() => {
        const fetchFilteredInsights = async () => {
        if (activeFilter === 1) {
            // Fetch all insights
            dispatch(getInsights(currentPage));
        } else if (activeFilter === 2) {
            // Fetch oldest insights
            dispatch(getOldestInsight(currentPage));
        } else if (activeFilter === 3) {
            // Fetch insights by alpha
            dispatch(getInsightByAlpha(currentPage));
        } 
        };

        fetchFilteredInsights();

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [activeFilter,currentPage]);

    const handleSearch = (search) => {
        dispatch(getInsightsBySearch({ search, page: currentPage }));
    };
    const filteredLinks = insights?.links?.filter((link) => link.label !== "&laquo; Previous" && link.label !== "Next &raquo;");

  return (  
    <>  
        <h1 className='heading-title'>LATEST INSIGHTS</h1>
        <SEO
            title="Stay Informed With The Latest News | Yellow Door Energy"
            description="Stay informed with the latest news, insightful blogs, and updates from Yellow Door Energy. Explore our valuable insights for a sustainable future."
            url={window.location.href}
        />
        {isLoading && (
            <div className={`loading-screen ${isLoading ? '' : 'hidden'}`}>
            <LoadingComponent />
            </div>
        )}
        <div className='h-auto sm:h-[225px] flex flex-col bg-primary-500 w-full mt-[80px] md:mt-[100px]'>
            <div className='screen-1 w-full py-8 sm:pb-8 ' >
                <div className='w-3/4 pb-3'>
                    <span className='   uppercase text-[#ffffff] md:text-2xl text-lg'>Latest</span>
                    <div className='flex items-center space-x-6'>
                        <span className='uppercase  text-[#ffffff] md:text-2xl text-lg  font-gotham-black'>Insights</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
                    <div className='flex items-center space-x-6 border-b border-b-[#ffffff] mb-3 sm:mb-0 sm:w-[33%] pb-1'>
                        <img width="23" height="23" className='w-[20px] h-[20px] object-contain' src={Search} alt='Search'  />
                        <input type='text' className='input' onChange={(e) => handleSearch(e.target.value)} placeholder='Search' />
                    </div>
                    <div className='flex self-end sm:self-start sm:items-center space-x-1 border-b border-b-[#ffffff] pb-1 relative group hover:border-b-0'>
                        <input type='text' className='input w-[100px]' placeholder='Filter by' />
                        <div className='hidden group-hover:opacity-1 group-hover:block duration-300 ease-in absolute right-0 min-h-full w-[200px] rounded-tr-[35px] rounded-bl-[35px] border border-primary-500 m-0 bg-[#ffffff] top-[100%]'>
                            <ul>
                                <li onClick={() => handleFitler(1)} className='text-primary-500 hover:text-secondary-500 text-xs md:text-sm 2xl:text-xl text-center py-2 border-b border-b-primary-500 cursor-pointer'>Newest to Oldest</li>
                                <li onClick={() => handleFitler(2)} className='text-primary-500 hover:text-secondary-500 text-xs md:text-sm 2xl:text-xl text-center py-2 border-b border-b-primary-500 cursor-pointer'>Oldest to Newest</li>
                                <li onClick={() => handleFitler(3)} className='text-primary-500 hover:text-secondary-500 text-xs md:text-sm 2xl:text-xl text-center py-2 cursor-pointer'>A to Z</li>
                            </ul>
                        </div>
                        <img width="23" height="23" className='w-[20px] h-[20px] object-contain' src={Filter} alt='Filter'  />
                    </div>
                </div>
               <div className='tab w-full border border-[#ffffff] flex flex-col sm:flex-row items-center justify-around mt-8 rounded-bl-[5px] rounded-tr-[5px] rounded-tl-[10px] rounded-br-[10px]'>
                    <div
                        className={`tab-content text-[#ffffff] duration-300 ease-in-out flex-1 px-3 py-1 text-center w-full ${
                        activeTab === 0 ? 'active font-gotham-black' : ''
                        }`}
                        onClick={() => handleTabClick(0)}
                    >
                        <span  className='text-base' >
                            All Insights
                        </span>
                    </div>
                    {tags?.map(({id,title_en,index}) => (
                        <div key={index}
                            className={`tab-content text-[#ffffff] duration-300 ease-in-out flex-1 px-3 py-1 text-center w-full ${
                            activeTab === id ? 'active font-gotham-black' : ''
                            }`}
                            onClick={() => handleTabClick(id)}
                        >
                            <Link className='text-base' >
                                {title_en}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className='screen-1 relative min-[85vh]'>
                <div className="mt-8 mb-16">
                    <div className='py-4'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 gap-4'>
                        {insights?.data?.map((insight,id) => (
                             <div key={id} className={`group lg:col-span-${id == 0 ? 2 : 1}`}>

                            <Link to={`/insights/${insight?.slug}`}>
                                <div className='image-container'><img  className='' src={insight?.image} alt={insight?.title_en}  /></div>
                                <div className='py-4 sm:px-5'>
                                    <p className='text-primary-500 font-gotham-bold uppercase 2xl:text-lg text-base group-hover:text-secondary-500 cursor-pointer duration-300 ease-in-out'>
                                        {insight?.title_en}
                                    </p>
                                    <p className=' 2xl:text-base text-sm'>{insight?.insight?.title_en}</p>
                                    <p className='text-xs text-primary-500 text-opacity-50  my-2'>{moment(insight?.date).format('LL')}</p>
                                    <p className='2xl:text-base text-sm'><StripTags policyText={truncate(insight?.text_en,200)} /></p>
                                    <div>
                                        <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500 '>Learn More</Link>
                                    </div>
                                </div>
                            </Link>
                         </div>
                        ))}
                    </div>
                    <div className='float-right'>
                        <ul className='flex items-center space-x-6'>
                            {filteredLinks?.map((link,id) => (
                                    <li key={id} onClick={() => handleCurrentPage(link?.url)} className={link?.active
                                    ? 'text-primary-500  font-gotham-black duration-200 ease-in text-opacity-1 cursor-pointer'
                                    : 'duration-200 ease-out cursor-pointer text-opacity'}>{link?.label}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
          
            <div>
               
            </div>
        </div>

        <div className='mb-32'>
            <Contact />
        </div>

        <div className='mt-32'>
            <OurOffices />
        </div>
    </>
  )

  
}

export default Insights