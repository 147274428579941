import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projectService from "./projectService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  projects: [],
  benefits: [],
  project: [],
  descriptions: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getProjects = createAsyncThunk(
  "projects/getAll",
  async (_, thunkApi) => {
    try {
      return await projectService.getProjects();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getProject = createAsyncThunk(
    "projects/getSingleProject",
    async (slug, thunkApi) => {
      try {
        return await projectService.getProject(slug);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
  );
  
export const getProjectByMarket = createAsyncThunk(
    "projects/getProjectByMarket",
    async (market_id, thunkApi) => {
      try {
        return await projectService.getProjectByMarket(market_id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
);

export const getOldestProject = createAsyncThunk(
    "projects/getOldestProject",
    async (_, thunkApi) => {
        try {
        return await projectService.getOldestProject();
        } catch (error) {
        const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();

          if (error.response && error.response.status === 500) {
            thunkApi.dispatch(setServerError(true));
          } 

        return thunkApi.rejectWithValue(message);
        }
    }
);

export const getProjectsBySearch = createAsyncThunk(
    "projects/getProjectsBySearch",
    async (search, thunkApi) => {
      try {
        return await projectService.getProjectsBySearch(search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
  );

export const getProjectByAlpha = createAsyncThunk(
    "projects/getProjectByAlpha",
    async (_, thunkApi) => {
        try {
        return await projectService.getProjectByAlpha();
        } catch (error) {
        const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();

          if (error.response && error.response.status === 500) {
            thunkApi.dispatch(setServerError(true));
          } 
    
        return thunkApi.rejectWithValue(message);
        }
    }
    );

export const getBenefits = createAsyncThunk(
    "projects/getBenefits",
    async (id, thunkApi) => {
      try {
        return await projectService.getBenefits(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
);

export const getDescription = createAsyncThunk(
    "projects/getDescription",
    async (id, thunkApi) => {
      try {
        return await projectService.getDescription(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
);


export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state) => {
        state.isLoading = true; 
        state.isSuccess = false; 
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getProjectByMarket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getOldestProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getProjectByAlpha.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getProjectsBySearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getProject.pending, (state) => {
        state.isLoading = true; 
        state.isSuccess = false; 
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.project = action.payload;
      })
      .addCase(getBenefits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.benefits = action.payload;
      })
      .addCase(getDescription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.descriptions = action.payload;
      })
  },
});

export const { reset } = projectSlice.actions;
export default projectSlice.reducer;