import React, { useContext, useEffect, useState } from 'react'
import arabic from '../assets/images/social-icons/arabic-character.svg'
import logo from '../assets/images/homescreen/logo.png'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { PopUpContext } from '../context/popUpContext';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../features/applications/applicationSlice'
import { getSocial } from '../features/socials/socialSlice'
import { getList } from '../features/markets/marketSlice'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const Navbar = () => {
    const [nav,setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav)
        scrollToTop();
    }
    const dispatch = useDispatch();
    const [marketActive, setMarketActive] = useState(false);
    const [aboutActive, setAboutActive] = useState(false);
    const [productActive, setProductActive] = useState(false);
    const [productV2Active, setProductV2Active] = useState(false);
    const { markets } = useSelector((state) => state.market);
    const { socials } = useSelector((state) => state.social);
    const { products } = useSelector((state) => state.application);
    const {popUp,setPopUp} = useContext(PopUpContext);

 
    const handlePop = () => {
        setPopUp(!popUp)
        setNav(false)
    }

    // Function to open the menu
    const handleMenuOpenMarket = () => {
        setMarketActive(!marketActive);
    };

    const handleMenuOpenAbout = () => {
        setAboutActive(!aboutActive);
    };

    const handleMenuOpenProduct = () => {
        setProductActive(!productActive);
    };

    const handleMenuOpenV2Product = () => {
        setProductV2Active(!productV2Active);
    };


    useEffect(() => {
        const fetchData = async () => {
            dispatch(getProducts());
            dispatch(getSocial());
            dispatch(getList());
        }
        fetchData();

    },[dispatch])

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    
  return (
    <>
        <header>
        <nav className='fixed top-0 left-0 w-full z-40'>
            {/* Navbar Top */}
            <div  className='bg-secondary-500 py-1 hidden sm:block'>
                <div className='screen-1 flex justify-end items-center text-primary-500 '>
                    <a href="tel:+97144543033" className="px-3 text-sm cursor-pointer py-1">+971 4 454 3033</a>
                    <div class="border-r-[1px] h-4"></div>
                    <a href="mailto:gosolar@yellowdoorenergy.com" class="px-3 text-sm cursor-pointer py-1">gosolar@yellowdoorenergy.com</a>
                    <div class="border-r-[1px] h-4"></div>
                    <div className='flex items-center space-x-4 border-r-[1px] px-3'>
                        {socials?.map((social,id) => (
                            <Link to={social?.link} target="_blank">
                                <img width="100%" height="100%" onClick={scrollToTop} key={id} className='cursor-pointer' src={social?.icon} alt={social?.title}/>
                            </Link>
                        ))}
                    </div>
                    {/* <div className='pl-2'>
                        <img width="" height="" className='cursor-pointer' src={arabic} alt="arabic-character" />
                    </div> */}
                </div>
            </div>
           
            
            {/* NavBar Bottom */}
            <div className='py-4 relative bg-[#ffffff]'>
                <div className='screen-1 flex justify-between items-center'>
                    <Link to={'/'}>
                        <img width="" height="" className='w-[100px] h-full cursor-pointer' src={logo} alt="yellow-door-logo" />
                    </Link>
                    <div>
                        <ul className='items-center xl:space-x-10 lg:space-x-6 w-full text-primary-500 hidden lg:flex'>
                            <li className='relative group'>
                                <Link className=' 2xl:text-lg text-sm hover:text-secondary-500 block mt-[10px] font-gotham-medium'> Solar Solutions</Link>
                                <div className='invisible opacity-0 group-hover:opacity-100 group-hover:visible duration-500 ease-in-out absolute -right-[124px] min-h-[90%] w-[300px] rounded-tr-[35px] rounded-bl-[35px] border border-primary-500 m-0 bg-[#ffffff] top-[54px] group-hover:z-30 -z-10'>
                                    <ul>
                                        <li onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 rounded-tr-[35px] hover:rounded-tr-[35px] duration-300 ease-in-out  text-sm 2xl:text-xl text-center px-2 py-3 border-b border-b-primary-500 cursor-pointer'>
                                            <Link to={'/solutions/PPA-power-purchase-agreement'}>
                                                Power Purchase Agreements
                                            </Link>
                                        </li>
                                        <li className='text-primary-500 group hover:text-secondary-500 rounded-bl-[35px] hover:rounded-bl-[35px] duration-300 ease-in-out text-sm 2xl:text-xl text-center px-2 py-3  cursor-pointer sublist-hvr'>
                                            Product Applications
                                            <ArrowForwardIcon className='arrow-hvr' sx={{ marginLeft: '20px' , marginBottom: '2px'}} />
                                            <div className='sublist'>
                                                <ul>
                                                    {products?.map((product,id) => (
                                                        <li key={id} onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 duration-300 ease-in-out text-sm 2xl:text-xl text-center px-2 pt-3 pb-[0.80rem] border-b border-b-primary-500 cursor-pointer'>
                                                            <Link to={`/solutions/${product?.slug}`}>
                                                                {product?.title_en}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className='relative group'>
                                <Link className=' 2xl:text-lg text-sm group-hover:text-secondary-500 block mt-[10px] font-gotham-medium'>Markets</Link>
                                <div className='dd-market invisible opacity-0 group-hover:opacity-100  group-hover:visible duration-500 ease-in-out  absolute -right-[65px] min-h-full w-[200px] rounded-tr-[35px] rounded-bl-[35px] border border-primary-500 m-0 bg-[#ffffff] top-[56px] group-hover:z-30 -z-10'>
                                    <ul>
                                        {markets?.map((market,index) => (
                                            <li key={index} onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 duration-300 ease-in-out text-sm 2xl:text-xl text-center px-2 py-3 border-b border-b-primary-500 cursor-pointer'>
                                                <Link to={`/markets/${market?.slug}`}>
                                                    {market?.title_en}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>

                            <li onClick={scrollToTop}><Link to={'/projects'} className=' 2xl:text-lg text-sm hover:text-secondary-500 block mt-[10px] font-gotham-medium'>Projects</Link></li>
                            <li onClick={scrollToTop}><Link to={'/insights'} className=' 2xl:text-lg text-sm hover:text-secondary-500 block mt-[10px] font-gotham-medium'>Insights</Link></li>
                            <li onClick={scrollToTop}><Link to={'/sustainability'} className=' 2xl:text-lg text-sm hover:text-secondary-500 block mt-[10px] font-gotham-medium'>Sustainability</Link></li>
                            <li onClick={scrollToTop}><Link to={'/careers'} className=' 2xl:text-lg text-sm hover:text-secondary-500 block mt-[10px] font-gotham-medium'>Careers</Link></li>
                            <li  className='relative group'>
                                <Link className=' 2xl:text-lg text-sm hover:text-secondary-500 block mt-[10px] font-gotham-medium'>About</Link>
                                <div className='invisible opacity-0 group-hover:opacity-100 group-hover:visible duration-500 ease-in-out absolute -right-[78px] min-h-full w-[200px] rounded-tr-[35px] rounded-bl-[35px] border border-primary-500 m-0 bg-[#ffffff] top-[56px]'>
                                    <ul>
                                        <li onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 rounded-tr-[35px] hover:rounded-tr-[35px] duration-300 ease-in-out  text-sm 2xl:text-xl text-center px-2 py-3 border-b border-b-primary-500 cursor-pointer'>
                                            <Link to={'/our-story'}>
                                                Our Story
                                            </Link>
                                        </li>
                                        <li onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 duration-300 ease-in-out text-sm 2xl:text-xl text-center px-2 py-3 border-b border-b-primary-500 cursor-pointer'>
                                          <Link to={'/our-values'}>Our Values</Link> 
                                        </li>
                                        <li onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 duration-300 ease-in-out text-sm 2xl:text-xl text-center px-2 py-3 border-b border-b-primary-500 cursor-pointer'>
                                            <Link to={'/teams'}>Our Team</Link> 
                                        </li>
                                        <li onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 duration-300 ease-in-out text-sm 2xl:text-xl text-center px-2 py-3 border-b border-b-primary-500 cursor-pointer'>
                                            <Link to={'/testimonials'}>Customer Testimonials</Link> 
                                        </li>
                                        <li onClick={scrollToTop} className='text-primary-500 hover:text-secondary-500 duration-300 ease-in-out text-sm 2xl:text-xl text-center px-2 py-3 cursor-pointer'>
                                            <Link to={'/policies'}>Our Policies</Link> 
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='hidden lg:block cursor-pointer group' onClick={() => setPopUp(!popUp)}>
                        <span className='text-secondary-500 md:text-[0.75rem] sm:text-sm  font-gotham-black uppercase screen-1 duration-300 relative z-10 p-0 w-full group-hover:text-primary-500  '>Book Free Consultation</span>
                        <div className='hidden lg:block shape-nav group-hover:shape-nav-hover duration-300 absolute right-0 top-0 w-[29%] 2xl:w-[25%] h-full z-0'></div>
                    </div>
                    

                    {/* MOILE MENU */}
                    <div className='lg:hidden z-10 cursor-pointer' onClick={handleNav}>
                        {nav ? <CloseIcon className='text-[#ffffff]'/> : <MenuIcon fontSize='large' />}
                    </div>
                    <div className={nav ? 'overflow-y-auto lg:hidden ease-in duration-300 absolute right-0 top-0 w-full h-screen bg-primary-500 bg-opacity-90 px-4 py-7 flex flex-col' : 'absolute top-0 h-screen right-[-100%] ease-in duration-500'}>
                            <ul className='h-full w-full text-secondary-500 text-center pt-12'>
                                <div>
                                    <li onClick={handleMenuOpenProduct}><Link className=' text-2xl py-4 inline-block hover:text-secondary-500'>Solutions {productActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Link></li>
                                    {productActive && (
                                        <>
                                           
                                        <li onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                            <Link to={'/solutions/PPA-power-purchase-agreement'}>
                                                Power Purchase Agreements
                                            </Link>
                                        </li>
                                        <li onClick={handleMenuOpenV2Product} className=' text-sm py-1 hover:text-secondary-500'>
                                            Product Applications {productV2Active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </li>
                                            {productV2Active && (
                                                <>
                                                    {products?.map((product,id) => (
                                                        <div key={id} onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                                            <Link to={`/solutions/${product?.slug}`}>
                                                                {product?.title_en}
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div>
                                    <li onClick={handleMenuOpenMarket}><Link className=' text-2xl py-4 inline-block hover:text-secondary-500'>Markets {marketActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Link></li>
                                    {marketActive && (
                                        <>
                                           
                                                {markets?.map((market,index) => (
                                                    <>
                                                        <div key={index} onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                                            <Link to={`/markets/${market?.slug}`}>
                                                                {market?.title_en}
                                                            </Link>
                                                            
                                                        </div>
                                                    </>
                                                ))}
                                            
                                        </>
                                    )}
                                </div>
                                <li onClick={handleNav}><Link to={'/projects'} className=' text-2xl py-4 inline-block hover:text-secondary-500'>Projects</Link></li>
                                <li onClick={handleNav}><Link to={'/insights'} className=' text-2xl py-4 inline-block hover:text-secondary-500'>Insights</Link></li>
                                <li onClick={handleNav}><Link to={'/sustainability'} className=' text-2xl py-4 inline-block hover:text-secondary-500'>Sustainability</Link></li>
                                <li onClick={handleNav}><Link to={'/careers'} className=' text-2xl py-4 inline-block hover:text-secondary-500'>Careers</Link></li>
                                <div>
                                    <li onClick={handleMenuOpenAbout}><Link className=' text-2xl py-4 inline-block hover:text-secondary-500'>About {aboutActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Link></li>
                                    {aboutActive && (
                                        <>
                                        <div onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                            <Link to={'/our-story'}>
                                                Our Story
                                            </Link>
                                        </div>
                                        <div onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                          <Link to={'/our-values'}>Our Values</Link> 
                                        </div>
                                        <div onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                            <Link to={'/teams'}>Our Team</Link> 
                                        </div>
                                        <div onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                            <Link to={'/testimonials'}>Customer Testimonials</Link> 
                                        </div>
                                        <div onClick={handleNav} className=' text-sm py-1 hover:text-secondary-500'>
                                            <Link to={'/policies'}>Our Policies</Link> 
                                        </div>

                                
                                        </>
                                    )}
                                </div>
                                <div onClick={handlePop} className='max-w-[300px] h-auto mt-10 justify-center mx-auto'>
                                    <button className='uppercase text-base py-[12px] px-[5px] 2xl:text-lg rounded-tl-[10px] font-gotham-medium rounded-br-[10px] text-primary-500 bg-secondary-500 hover:bg-primary-500 
                                     w-full hover:text-secondary-500 border border-secondary-500 duration-300 ease-in'>Book Free Consultation</button>
                                </div>
                            </ul>          
                    </div>
                </div> 
            </div>
        </nav>
        </header>
    </>
  )
}

export default Navbar