import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'

const ProductApplication = ({products}) => {
  return (
    <>
        <div className='screen-1 py-3'>
            <div className='flex items-end justify-between'>
                <div className='w-full lg:3/4'>
                    <span className='   uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Our</span>
                    <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                        <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>
                            product applications
                        </span>
                        <div className='space-y-1 mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={75} />
                            <Line width={150} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-primary-500'>
            <div className='screen-1 bg-primary-500'>
                <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:gap-6 md:gap-24 py-14 lg:py-0'>
                    {products?.map((product,id) => (
                      
                            <div key={id} className='shape-2 group bg-contain relative bg-center md:bg-left cursor-pointer'>
                                <div className='absolute w-fit top-1/2 left-[45%] group-hover:md:top-[40%] group-hover:top-[30%] duration-[0.4s] ease-in transform -translate-x-1/2 -translate-y-1/2'>
                                    <div>
                                        <Link to={`/solutions/${product?.slug}`}>
                                            <p className='font-gotham-black text-primary-500 text-lg sm:text-xl 2xl:text-2xl'>{product?.normalText_en}</p>
                                            <p className='font-gotham-black text-primary-500 text-lg sm:text-xl 2xl:text-2xl'>{product?.boldText_en}</p>
                                            <p className='font-gotham-black text-primary-500 text-lg sm:text-xl 2xl:text-2xl'>{product?.parenthesis_en}</p>
                                        </Link>
                                    </div>  
                                </div>
                            </div> 
                 
                    ))}

                    {/* <div className='card-product col-span-4 bg-[#ffffff] transition-all duration-200 ease-in w-[300px] h-[300px] relative -skew-x-12 rounded-tl-[50px] rounded-br-[50px] justify-self-center cursor-pointer'>
                        <div className='absolute w-full top-1/2 left-[70%] transform -translate-x-1/2 -translate-y-1/2'>
                            <div className='space-y-1'>
                                <p className='text-primary-500 text-2xl ' style={{transform: 'skewX(12deg)'}}>Battery Energy<p className='font-gotham-bold'>Storage System</p>(BESS)</p>
                            </div>  
                        </div>
                        <div className='border-4 border-secondary-500 w-[300px] h-[300px] absolute left-7 top-5 -skew-x-4 rounded-tl-[50px] rounded-br-[50px] z-0'></div>  
                    </div> */}
                    {/* <div className='card-product col-span-4 bg-[#ffffff] transition-all duration-200 ease-in w-[300px] h-[300px] relative -skew-x-12 rounded-tl-[50px] rounded-br-[50px] justify-self-center ml-5 cursor-pointer'>
                        <div className='absolute w-full top-1/2 left-[70%] transform -translate-x-1/2 -translate-y-1/2'>
                            <div className='space-y-1'>
                                <p className='text-primary-500 text-2xl ' style={{transform: 'skewX(12deg)'}}>Electric Vehicle<p className='font-gotham-bold'>Charging System</p>(EV)</p>
                            </div>  
                        </div>
                        <div className='border-4 border-secondary-500 w-[300px] h-[300px] absolute left-7 top-5 -skew-x-4 rounded-tl-[50px] rounded-br-[50px] z-0'></div>  
                    </div> */}
                </div>
            </div>
        </div>
     
    </>
  )
}

export default ProductApplication