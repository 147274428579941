import React, { useEffect, useState } from 'react'
import Team from '../assets/images/about/Team.png'
import Mile from '../assets/images/about/final.png'
import Line from '../shapes/Line'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { getInfo } from '../features/about-us/aboutUsSlice'
import StripTags from '../utils/StripTags'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getBanner } from '../features/banners/bannerSlice'
import SEO from '../components/SEO'
const OurStory = () => {

  const [stories, setStories] = useState([]);
  const [boards, setBoards] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [shareholders, setShareholders] = useState([]);

  const dispatch = useDispatch();
  const { banner } = useSelector((state) => state.banner);
  const location = useLocation();
  const slug = location?.pathname.split('/')[1];

  useEffect(() => {
      dispatch(getBanner(slug));
  },[dispatch])

  useEffect(() => {
    const fetchData = async () => {
        const storyData = await dispatch(getInfo());
        setStories(storyData?.payload.stories);
        setBoards(storyData?.payload.boards);
        setMilestones(storyData?.payload.milestones);
        setShareholders(storyData?.payload.shareholders);
    };

    fetchData();

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, [dispatch]);

  return (
    <>
        <h1 className='heading-title'>OUR STORY</h1>
        <SEO
            title="Our Story | Towards A Just Transition | Yellow Door Energy"
            description="Together with a team of 150+ dedicated experts in solar and battery energy storage system (BESS) solutions, Yellow Door Energy is well positioned to help businesses reach Net Zero targets and accelerate the just transition."
            url={window.location.href}
        />
            <div className='mt-[80px] md:mt-[110px]'>
                <div className='relative lg:flex lg:h-[344px]'>
                    <img width="1600" height="800" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[35%]' src={banner?.image} alt={banner?.title_en}  />
                    <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[76%] w-full '>
                    </div>
                    <div className='header-section-pad relative flex w-full screen-1'>
                        <div className='lg:ml-auto lg:w-[70%] lg:pl-[30px]'>
                            <div className='w-full'>
                                <span className='uppercase text-[#ffffff] text-2xl lg:text-xl  font-gotham-bold'>{banner?.title_en}</span>
                            </div>
                            <div className='w-full'> 
                                <span className='text-[#ffffff] lg:text-base  2xl:text-lg my-3 inline-block '>
                                <StripTags policyText={banner?.text_en} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mission and Vision */}
            <section>
              <div className='mt-16 lg:mt-0'>
                  <div className='screen-1'>
                      <div className='flex flex-col lg:items-center space-y-8  md:py-0 md:space-y-0 lg:mt-3'>
                        {stories?.slice(0,2).map((story,id) => (
                            <div key={id} className='flex flex-col sm:flex-row w-[94%] lg:ml-auto 2xl:w-[91.5%] lg:py-4'>
                              <img width="87" height="70" className='w-[60px] md:w-[8%] xl:w-[70px] h-full object-contain block' src={story?.icon}alt={story?.subtitle_en}  />
                              <div className='flex-col flex md:mx-auto lg:mx-8 lg:w-[154px]'>
                                  <span className='uppercase text-primary-500 text-xl md:text-2xl w-[180px]'>{story?.title_en}</span>
                                  <span className='uppercase  text-primary-500 text-xl md:text-2xl font-gotham-black w-[180px]'>{story?.subtitle_en}</span>
                              </div>
                              <div className='ml-auto lg:ml-0 sm:w-3/4 lg:w-full lg:pl-[13px]'>
                                  <p className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'>
                                      <StripTags policyText={story?.text_en} />
                                  </p>
                              </div>
                          </div> 
                        ))}
                      </div> 
                      <div className='flex flex-col sm:flex-row lg:items-center justify-center xl:justify-end w-[94%] 2xl:w-[91.5%] lg:ml-auto lg:h-[100px] mt-12 lg:mt-0'>
                            <img width="87" height="70" className='w-[60px] md:w-[8%] xl:w-[70px] h-full object-contain block' src={stories[2]?.icon} alt={stories[2]?.title_en}  />
                            <div className='flex-col flex md:mx-auto lg:mx-8'>
                                <span className=' uppercase text-primary-500 text-xl md:text-2xl '>{stories[2]?.title_en}</span>
                                <span className='uppercase  text-primary-500 text-xl md:text-2xl font-gotham-black'>{stories[2]?.subtitle_en}</span>
                            </div>
                            <div className='ml-auto lg:ml-0 sm:w-3/4 lg:w-full lg:pl-[45px]'>
                                <p className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'>
                                    <StripTags policyText={stories[2]?.text_en} />
                                </p>
                            </div>
                      </div>      
                  </div>  
              </div>
            </section>
            
            {/* MileStones */}
            <section className='my-16'>
                <div className='screen-1'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12 lg:col-span-5'>
                            <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Company</span>
                            <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                                <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black leaing-none'>Milestones</span>
                                <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                                    <Line width={60} />
                                    <Line width={120} />
                                </div>
                            </div>
                            <div className='mt-24'> 
                                <img width="482" height="100" className='w-full object-contain' src={Mile} alt='Milestones'  />
                            </div>
                        </div>

                        <div className='col-span-12 lg:col-span-7  mt-8 md:mt-0'>
                          {milestones?.map(({year_en,text_en,id}) => (
                              <div key={id} className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list mb-8 md:w-[85%]'>
                              <span className='text-primary-500 font-gotham-bold me-3'>{year_en}</span>
                                  <StripTags policyText={text_en} />
                              </div>
                            ))
                          }
                        </div>
                    </div>
                </div>
            </section>


            {/* Our Boards */}
            <section className='my-16'>
                <div className='screen-1'>
                  <span className='uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Our</span>
                  <div className='flex flex-col md:flex-row md:items-center'>
                      <span className='uppercase text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none w-[25%] leading-none'>Board Members</span>
                      <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2 lg:mt-0'>
                          <Line width={60} />
                          <Line width={120} />
                      </div>
                  </div>
                </div>
                {boards?.map((board, id) => (
                  <>
                      <div key={id} className='screen-1'>
                        <div className='grid grid-cols-12 my-12 border-b-1 border-primary-500'>
                          <div className='flex flex-col md:flex-row md:items-center lg:items-start lg:flex-col col-span-5'>
                              <div>
                                  <img width="329" height="329" src={board?.image} className='w-full h-full object-contain rounded-tr-[25px] rounded-bl-[25px] lg:rounded-tr-[50px] lg:rounded-bl-[50px] border border-primary-500' alt={board?.name_en}  />
                              </div>
                              <div className='mt-4 p-3'>
                                  <span className='text-lg 2xl:text-2xl text-primary-500 font-gotham-black'>{board?.name_en}</span>
                              </div>
                          </div>
                          <div className='h-full col-span-12 lg:col-span-7 mt-6 lg:mt-0'>
                              <p className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'>
                                  <StripTags policyText={board?.text_en} />
                              </p>
                          </div>
                        </div>
                      </div>
                      {id !== boards.length - 1 && <hr className='bg-primary-500' />}
                  </>
                ))}
            </section>

            {/* ShareHolders */}
            <section className='my-16'>
                <div className='screen-1'>
                  <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Our</span>
                    <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                        <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>SHAREHOLDERS</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div> 

                  {shareholders?.map(({image,text_en},id) => (
                    <>
                        <div key={id} className='grid md:grid-cols-12'>
                          <div className='md:col-span-5'>
                            <img width="242" height="242" className='w-[250px] object-contain' src={image} alt='Shareholders'  />
                          </div>
                      
                            <div className='md:col-span-7 text-sm md:text-base 2xl:text-lg text-primary-500 custom-list place-self-center'>
                                <p>
                                    <StripTags policyText={text_en} />
                                </p>
                            </div>
                        </div>
                    </>
                  ))}
                </div>
            </section>

            <div className='my-16'>
                <Contact />
            </div>

            <div className='my-16'>
                <OurOffices />
            </div>

    </>
  )
}

export default OurStory