import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/global.css'
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import 'lightbox2/dist/css/lightbox.css';
import 'lightbox2/dist/js/lightbox.js';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { PopupProvider } from './context/popUpContext';
import { HelmetProvider } from 'react-helmet-async';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PopupProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </PopupProvider>
    </Provider>
  </React.StrictMode>
);

