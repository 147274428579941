import React, { useContext } from 'react'
import { PopUpContext } from '../context/popUpContext'
import CountUp from 'react-countup';
import Line from '../shapes/Line'
import StripTags from '../utils/StripTags';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";


const Hero = ({counters, banners}) => {

    const {setPopUp, popUp} = useContext(PopUpContext);

  return (
    <>
        <div className='w-full lg:h-[80vh]'>
            <Swiper 
                slidesPerView={1}    
                modules={[Navigation,Autoplay]}
                navigation={true}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                className="mySwiper"
            >
                {banners?.map((banner,index) => (
                    <SwiperSlide key={index}>
                        {banner.image.endsWith('.mp4') || banner.image.endsWith('.webm') ? (
                            <video muted autoPlay loop className='w-full h-[300px] lg:h-[80vh] object-cover' src={banner.image} alt='Homepage - Yellow Door Energy' />
                        ) : (
                            <img width="1920" height="793" className='w-full h-[300px] lg:h-[80vh] object-cover' src={banner.image} alt='Yellow Door Energy' />
                        )}
                    </SwiperSlide>
                ))}
                </Swiper>  
        </div>
        <div className='w-full bg-primary-500 p-4 lg:hidden md:block text-center'>
            <div className=''>
                <h2 className='my-1 uppercase text-[#ffffff] lg:text-lg md:text-base leading-none'>
                    Decarbonize
                </h2>
                <span className='capitalize text-[#ffffff]  font-gotham-black text-lg leading-none'>your business today</span>
                <div>
                <button onClick={() => setPopUp(!popUp)} className="mt-2 px-1 py-1 w-[280px] 2xl:w-[320px] h-[40px] rounded-tl-[10px] rounded-tr-[5px] rounded-br-[10px] rounded-bl-[5px] text-center bg-secondary-500 text-primary-500
                hover:bg-primary-500 hover:text-secondary-500 duration-300 border border-transparent hover:border-secondary-500 uppercase font-gotham-medium text-sm 2xl:text-base">Book Free Consultation</button>
                </div>
                <div className='w-full mt-4'>
                    <span className='uppercase text-[#ffffff] text-lg leading-none'>Our</span>
                    <div className='flex items-center flex-col'>
                        <span className='uppercase text-center text-[#ffffff] ml-2 text-lg font-gotham-black leading-none'>Results</span>
                    </div>
                </div>
            </div>   
        </div>
        <div className='w-full lg:max-h-[123px] 2xl:max-h-[150px] bg-[#023A6C] bg-opacity-10'>
            <div className='screen-1 pt-4 pb-2 relative z-10'>
                <div className='grid lg:grid-cols-5 w-full gap-4 grid-cols-1'>
                    <div className='lg:col-span-2 relative hidden lg:block'>
                        <div className='w-[390px] 2xl:w-[500px] h-[270px] bg-primary-500 rounded-br-[50px] rounded-tl-[50px] -skew-x-[15deg]
                         p-0 absolute top-[40px] -left-[3rem] 2xl:-left-20 -mt-[12.9rem] 2xl:-mt-[10.5rem]  hidden lg:block'></div>
                            <div className='relative z-10 lg:-top-[70%] 2xl:-top-[60%]'>
                                <div className='space-y-1 my-2'>
                                    <Line width={60} />
                                    <Line width={120} />
                                </div>
                                <div className='my-4'>
                                    <h2 className='my-1 uppercase text-[#ffffff] lg:text-lg md:text-base leading-none'>
                                        Decarbonize
                                    </h2>
                                    <span className='capitalize text-[#ffffff]  font-gotham-black text-lg leading-none'>your business today</span>
                                </div>
                                <div>
                                    <button onClick={() => setPopUp(!popUp)} className="px-1 py-1 w-[280px] 2xl:w-[320px] h-[40px] rounded-tl-[10px] rounded-tr-[5px] rounded-br-[10px] rounded-bl-[5px] text-center bg-secondary-500 text-primary-500
                                hover:bg-primary-500 hover:text-secondary-500 duration-300 border border-transparent hover:border-secondary-500 uppercase font-gotham-medium text-sm 2xl:text-base">Book Free Consultation</button>
                                </div>
                                <div className='w-full mt-4'>
                                    <span className='uppercase text-[#ffffff] text-lg leading-none'>Our</span>
                                    <div className='flex items-center space-x-6'>
                                        <span className='uppercase text-[#ffffff] text-lg font-gotham-black leading-none'>Results</span>
                                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2 lg:mt-0'>
                                            <Line width={60} />
                                            <Line width={120} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                        {counters?.map((counter,id) => (
                            <div key={id} className='flex flex-col items-center lg:items-start py-4 lg:py-0'>
                            <CountUp end={counter?.number} duration={4.75} className='text-secondary-500  font-gotham-black text-3xl 2xl:text-5xl  mb-1' />
                            <p className='text-primary-500 uppercase r 2xl:text-lg my-1 lg:my-0 text-sm'>{counter?.title_en}</p>
                            <p className='text-xs text-primary-500/70'><StripTags policyText={counter?.text_en} /></p>
                        </div>
                        ))}
                </div>
            </div>
        </div>

    </>
  )
}

export default Hero