import React, { useEffect } from 'react'
import Line from '../shapes/Line'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { useDispatch, useSelector } from 'react-redux'
import { getValues } from '../features/values/valueSlice'
import StripTags from '../utils/StripTags'
import { useLocation } from 'react-router-dom'
import { getBanner } from '../features/banners/bannerSlice'
import SEO from '../components/SEO'


const OurValues = () => {

    const { banner } = useSelector((state) => state.banner);
    const { values } = useSelector((state) => state.value);
    const dispatch = useDispatch();
    const location = useLocation();
    const slug = location?.pathname.split('/')[1];
    useEffect(() => {
        dispatch(getValues());
        dispatch(getBanner(slug));


     
    },[dispatch])

    console.log(values);
  return (
    <>
        <h1 className='heading-title'>OUR VALUES</h1>
        <SEO
            title="Our Values for Our Business | Yellow Door Energy"
            description="We are a values-driven company and our values are Sustainability, Passion, Integrity, Collaboration and Agility. Take a look at what this means for our business."
            url={window.location.href}
        />
        <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[344px]'>
                <img width="1600" height="800" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[35%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[76%] w-full '>
                </div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                    <div className='lg:ml-auto lg:w-[70%] lg:pl-[63px]'>
                        <div className='w-full lg:w-[45%]'>
                            <span className='uppercase text-[#ffffff] text-2xl lg:text-xl font-gotham-bold'>{banner?.title_en}</span>
                        </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff] lg:text-base  2xl:text-lg my-3 inline-block '>
                              <StripTags policyText={banner?.text_en} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='screen-1 mt-16'>
            {values?.map((value,id) => (
                <div key={id} className='grid md:grid-cols-3 lg:gap-24 mb-12'>
                    <div className='col-span-3 md:col-span-1 relative h-fit'>
                        <img width="482" height="357" className='w-full object-contain rounded-tr-[50px] rounded-bl-[50px]' src={value?.image} alt={value.title_en}  />
                        <div className='absolute right-4 bottom-4 rounded-full bg-[#ffffff] w-[90px] h-[90px] 2xl:w-[120px] 2xl:h-[120px]'>
                            <img width="70" height="70" className='w-[75px] 2xl:w-[100px] relative top-1/2 -translate-y-1/2 mx-auto object-contain' src={value?.icon} alt={value.title_en}  />
                        </div>
                    </div>
                    <div className='col-span-3 lg:col-span-2 mt-6 lg:mt-0'>
                        <div className='w-full flex flex-col'>
                            <span className='   uppercase text-primary-500 2xl:text-3xl text-xl'>
                                {value.title_en}
                            </span>
                            <span className='uppercase  text-primary-500 2xl:text-3xl text-xl  font-gotham-black'>
                                {value.subtitle_en}
                            </span>
                            <div className='space-y-1 w-full my-3'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                        <div className='mt-1'>
                            <p className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'>
                                <StripTags policyText={value.text_en} />
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>

        <div className='my-16'>
            <Contact />
        </div>

        <div className='my-16'>
            <OurOffices />
        </div>
    </>
  )
}

export default OurValues