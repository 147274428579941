import { Box, TextField } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { PopUpContext } from '../context/popUpContext';
import Line from '../shapes/Line';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../config';

const LetsTalk = () => {
    const theme = useTheme();
    const recaptchaRef = useRef();
    const [age, setAge] = useState('');
    const [email,setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [complaint, setComplaint] = useState('');
    const [complaintError, setComplaintError] = useState('');
    const [name,setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [company,setCompany] = useState('');
    const [companyError, setCompanyError] = useState('');
    const [country,setCountry] = useState('');
    const [countryError, setCountryError] = useState('');
    const [mobile,setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [message,setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const [success,setSuccess] = useState('');
    const [capVal, setCapVal] =  useState(null);

    const {popUp,setPopUp} = useContext(PopUpContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
          return;
        }

        try {

            const response = await axios.post(`${config.apiUrl}/api/contact`, { 
              email,message,mobile,country,name,company
             });

            setSuccess(response.data);
            setTimeout(() => {
                setSuccess('');
            }, 3000);

            setEmail('');
            setMobile('');
            setMessage('');
            setCompany('');
            setCountry('');
            setName('');

            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
            }

        } catch (error) {
            console.log(error)
        }
      
    }

    const validateForm = () => {
      // Validate email
      if (!email || !isEmailValid(email)) {
        setEmailError('Invalid email format');
        setTimeout(() => {
          setEmailError('');
        }, 3000);
  
        return false;
      }
  
      if (!mobile || !isMobileValid(mobile)) {
        setMobileError('Invalid Mobile Number');
        setTimeout(() => {
          setMobileError('');
        }, 3000);
  
        return false;
      }
  
    // Validate name
    if (!name) {
      setNameError('Name is required');
      setTimeout(() => {
        setNameError('');
      }, 3000);
      return false;
    }
  
    // Validate country
    if (!country) {
      setCountryError('Country is required');
      setTimeout(() => {
        setCountryError('');
      }, 3000);
      return false;
    }
  
    // Validate message
    if (!message) {
      setMessageError('Message is required');
      setTimeout(() => {
        setMessageError('');
      }, 3000);
      return false;
    }
  
    if (!company) {
      setCompanyError('Company is required');
      setTimeout(() => {
        setCompanyError('');
      }, 3000);
      return false;
    }
  
  
      // Add additional validations here if needed
  
      // Reset any previous errors
      setEmailError('');
      setMobileError('');
      setNameError('');
      setCountryError('');
      setMessageError('');
      setCompanyError('');
      return true;
    };
  

    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    const isMobileValid = (mobile) => {
      const mobileRegex = /^[0-9\s+]+$/;
      return mobileRegex.test(mobile);
    };

  
  return (
    <>
    <div >
        <div className={`${'popUp-xs transform  ease-in w-full h-full opacity-100 bg-primary-500 z-50 rounded-tr-[100px] rounded-bl-[100px]'}`}>
           <div className='flex justify-center items-center w-full h-[600px] my-auto relative' style={{minWidth: 'fit-content'}}>
                <div className='space-y-8 mt-4 md:mt-0'>
                    <div>
                        <span className='uppercase text-[#ffffff] 2xl:text-2xl text-xl'>Let's</span>
                        <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                            <span className='uppercase text-[#ffffff] 2xl:text-3xl text-xl font-gotham-black'>Talk</span>
                            <div className='space-y-1 mt-2 md:mt-0 2xl:mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                        <div > 
                            <form onSubmit={handleSubmit}>
                                <div className='flex 2xl:flex-row flex-col sm:items-center '>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '100%',
                                            [theme.breakpoints.up('sm')]: {
                                                width: '25ch', m:0 ,// Adjust width on screens >= sm breakpoint
                                              },
                                              [theme.breakpoints.up('md')]: {
                                                width: '25ch', // Adjust width on screens >= md breakpoint
                                              },
                                              [theme.breakpoints.up('lg')]: {
                                                width: '25ch',mx:1 // Adjust width on screens >= lg breakpoint
                                              },},
                                            '& .MuiFilledInput-root': { backgroundColor: 'transparent',color: '#ffffff',
                                            fontWeight:'700'},
                                            '& .MuiInput-root': { color: '#ffcc00' },
                                            '& .MuiInput-root:before': {borderBottom: '1px solid #ffcc00'},
                                            '& .MuiInput-root:after': {borderBottom: '2px solid #ffcc00'},
                                            '& .MuiInputLabel-formControl': {color: '#ffffff',fontWeight:'700',margin:'0'},
                                            '& .MuiInputLabel-formControl.Mui-focused': {color:'#ffcc00'},
                                            '& .MuiInput-input': {color:'#ffffff'},
                                            '& .Mui-focused': {color: '#ffcc00',fontWeight:'700',backgroundColor:'transparent'},
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        >
                                           <TextField
                                            id="standard-name"
                                            label="Name"
                                            value={name}
                                            name='name'
                                            type="text"
                                            error={!!nameError}
                                            helperText={nameError}
                                            variant="standard"
                                            onChange={(e) => setName(e.target.value)}
                                            />
                                    </Box>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '100%',
                                            [theme.breakpoints.up('sm')]: {
                                                width: '25ch', m:0 ,// Adjust width on screens >= sm breakpoint
                                              },
                                              [theme.breakpoints.up('md')]: {
                                                width: '25ch', // Adjust width on screens >= md breakpoint
                                              },
                                              [theme.breakpoints.up('lg')]: {
                                                width: '25ch',mx:1 // Adjust width on screens >= lg breakpoint
                                              },},
                                            '& .MuiFilledInput-root': { backgroundColor: 'transparent',color: '#ffffff',
                                            fontWeight:'700'},
                                            '& .MuiInput-root:before': {borderBottom: '1px solid #ffcc00'},
                                            '& .MuiInput-root:after': {borderBottom: '2px solid #ffcc00'},
                                            '& .MuiInputLabel-formControl': {color: '#ffffff',fontWeight:'700',margin:'0'},
                                            '& .MuiInputLabel-formControl.Mui-focused': {color:'#ffcc00'},
                                            '& .MuiInput-input': {color:'#ffffff'},
                                            '& .Mui-focused': {color: '#ffcc00',fontWeight:'700',backgroundColor:'transparent'},
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        >
                                            <TextField
                                            id="standard-email"
                                            label="Email"
                                            name='email'
                                            value={email}
                                            error={!!emailError}
                                            helperText={emailError}
                                            type="email"
                                            variant="standard"
                                            onChange={(e) => setEmail(e.target.value)}
                                            />
                                    </Box>
                                </div>
                                <div className='flex sm:items-center  2xl:flex-row flex-col'>
                                <Box
                                      component="form"
                                      sx={{
                                          '& .MuiTextField-root': { m: 1, width: '100%',
                                          [theme.breakpoints.up('sm')]: {
                                              width: '25ch', m:0 ,// Adjust width on screens >= sm breakpoint
                                            },
                                            [theme.breakpoints.up('md')]: {
                                              width: '25ch', // Adjust width on screens >= md breakpoint
                                            },
                                            [theme.breakpoints.up('lg')]: {
                                              width: '25ch',mx:1 // Adjust width on screens >= lg breakpoint
                                            },},
                                          '& .MuiFilledInput-root': { backgroundColor: 'transparent',color: '#ffffff',
                                          fontWeight:'700'},
                                          '& .MuiInput-root:before': {borderBottom: '1px solid #ffcc00'},
                                          '& .MuiInput-root:after': {borderBottom: '2px solid #ffcc00'},
                                          '& .MuiInputLabel-formControl': {color: '#ffffff',fontWeight:'700',margin:'0'},
                                          '& .MuiInputLabel-formControl.Mui-focused': {color:'#ffcc00'},
                                          '& .MuiInput-input': {color:'#ffffff'},
                                          '& .Mui-focused': {color: '#ffcc00',fontWeight:'700',backgroundColor:'transparent'},
                                      }}
                                      noValidate
                                      autoComplete="off"
                                      >
                                          <TextField
                                          id="standard-country"
                                          label="City / Country"
                                          type="text"
                                          value={country}
                                          error={!!countryError}
                                          helperText={countryError}
                                          name="country"
                                          variant="standard"
                                          onChange={(e) => setCountry(e.target.value)}
                                          />
                                  </Box>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '100%',
                                            [theme.breakpoints.up('sm')]: {
                                                width: '25ch', m:0 ,// Adjust width on screens >= sm breakpoint
                                              },
                                              [theme.breakpoints.up('md')]: {
                                                width: '25ch', // Adjust width on screens >= md breakpoint
                                              },
                                              [theme.breakpoints.up('lg')]: {
                                                width: '25ch',mx:1 // Adjust width on screens >= lg breakpoint
                                              },},
                                            '& .MuiFilledInput-root': { backgroundColor: 'transparent',color: '#ffffff',
                                            fontWeight:'700'},
                                            '& .MuiInput-root:before': {borderBottom: '1px solid #ffcc00'},
                                            '& .MuiInput-root:after': {borderBottom: '2px solid #ffcc00'},
                                            '& .MuiInputLabel-formControl': {color: '#ffffff',fontWeight:'700',margin:'0'},
                                            '& .MuiInputLabel-formControl.Mui-focused': {color:'#ffcc00'},
                                            '& .MuiInput-input': {color:'#ffffff'},
                                            '& .Mui-focused': {color: '#ffcc00',fontWeight:'700',backgroundColor:'transparent'},
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        >
                                            <TextField
                                            id="standard-email"
                                            label="Phone Number"
                                            type="text"
                                            value={mobile}
                                            error={!!mobileError}
                                            helperText={mobileError}
                                            name="mobile"
                                            variant="standard"
                                            onChange={(e) => setMobile(e.target.value)}
                                            />
                                    </Box>
                                </div>
                                <div className='flex sm:items-center  2xl:flex-row flex-col mb-3'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '100%',
                                            [theme.breakpoints.up('sm')]: {
                                                width: '25ch', m:0 ,// Adjust width on screens >= sm breakpoint
                                              },
                                              [theme.breakpoints.up('md')]: {
                                                width: '25ch', // Adjust width on screens >= md breakpoint
                                              },
                                              [theme.breakpoints.up('lg')]: {
                                                width: '25ch',mx:1 // Adjust width on screens >= lg breakpoint
                                              },},
                                            '& .MuiFilledInput-root': { backgroundColor: 'transparent',color: '#ffffff',
                                            fontWeight:'700'},
                                            '& .MuiInput-root:before': {borderBottom: '1px solid #ffcc00'},
                                            '& .MuiInput-root:after': {borderBottom: '2px solid #ffcc00'},
                                            '& .MuiInputLabel-formControl': {color: '#ffffff',fontWeight:'700',margin:'0'},
                                            '& .MuiInputLabel-formControl.Mui-focused': {color:'#ffcc00'},
                                            '& .MuiInput-input': {color:'#ffffff'},
                                            '& .Mui-focused': {color: '#ffcc00',fontWeight:'700',backgroundColor:'transparent'},
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        >
                                            <TextField
                                            id="message"
                                            label="Message"
                                            name='message'
                                            error={!!messageError}
                                            helperText={messageError}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => setMessage(e.target.value)}
                                            />
                                    </Box>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '100%',
                                            [theme.breakpoints.up('sm')]: {
                                                width: '25ch', m:0 ,// Adjust width on screens >= sm breakpoint
                                              },
                                              [theme.breakpoints.up('md')]: {
                                                width: '25ch', // Adjust width on screens >= md breakpoint
                                              },
                                              [theme.breakpoints.up('lg')]: {
                                                width: '25ch',mx:1 // Adjust width on screens >= lg breakpoint
                                              },},
                                            '& .MuiFilledInput-root': { backgroundColor: 'transparent',color: '#ffffff',
                                            fontWeight:'700'},
                                            '& .MuiInput-root:before': {borderBottom: '1px solid #ffcc00'},
                                            '& .MuiInput-root:after': {borderBottom: '2px solid #ffcc00'},
                                            '& .MuiInputLabel-formControl': {color: '#ffffff',fontWeight:'700',margin:'0'},
                                            '& .MuiInputLabel-formControl.Mui-focused': {color:'#ffcc00'},
                                            '& .MuiInput-input': {color:'#ffffff'},
                                            '& .Mui-focused': {color: '#ffcc00',fontWeight:'700',backgroundColor:'transparent'},
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        >
                                            <TextField
                                            id="company-name"
                                            label="Company Name"
                                            type="text"
                                            value={company}
                                            error={!!companyError}
                                            helperText={companyError}
                                            name="company"
                                            variant="standard"
                                            onChange={(e) => setCompany(e.target.value)}
                                            />
                                    </Box>
                                </div>
                                <ReCAPTCHA 
                                ref={recaptchaRef}
                                sitekey={config.recaptcha}
                                onChange={(val) => setCapVal(val)}
                                />
                                {success && <p className="text-secondary-500 font-gotham-black text-lg">{success}</p>}
                            

                                <div className='w-32 xl:w-36 2xl:w-48 h-auto mt-10 justify-center mx-auto sm:mx-0 sm:float-right'>
                                    <button type='submit' disabled={!capVal} onClick={handleSubmit} className='uppercase text-base 2xl:text-lg rounded-tl-[10px] font-gotham-medium rounded-br-[10px] text-primary-500 bg-secondary-500 hover:bg-primary-500 
                                     w-full hover:text-secondary-500 border border-secondary-500 duration-300 ease-in'>Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LetsTalk