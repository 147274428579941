import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import solutionService from "./solutionService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  benefits: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getBenefits = createAsyncThunk(
  "solutions/getBenefits",
  async (_, thunkApi) => {
    try {
      return await solutionService.getBenefits();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const solutionSlice = createSlice({
  name: "solution",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBenefits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.benefits = action.payload;
      })
  },
});

export const { reset } = solutionSlice.actions;
export default solutionSlice.reducer;