import React, { useEffect } from 'react'
import OurOffices from '../components/OurOffices'
import { getBanner } from '../features/banners/bannerSlice';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StripTags from '../utils/StripTags';
import { getPolicy } from '../features/externals/externalSlice';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const Policies = () => {

    const { banner } = useSelector((state) => state.banner);
    const { policy } = useSelector((state) => state.external);

    const dispatch = useDispatch();
    const location = useLocation();
    const slug = location?.pathname.split('/')[1];
    useEffect(() => {
        dispatch(getBanner(slug));
        dispatch(getPolicy());
    },[dispatch])
   
  return (
    <>
        <h1 className='heading-title'>OUR POLICIES</h1>
        <SEO
            title="Our Policies | Yellow Door Energy"
            description="We have a responsibility to carry out our work with respect, honesty, transparency and in full accord with the law at Yellow Door Energy."
            url={window.location.href}
        />
        <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[450px]'>
                <img width="" height="" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[45%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[70%] w-full '></div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                    <div className='lg:ml-auto lg:w-[55%]'>
                    <div >
                        <div className='w-full text-start lg:w-[70%] 2xl:w-[45%] pb-2'>
                            <span className='uppercase text-[#ffffff] 2xl:text-2xl text-xl font-gotham-bold'>{banner?.title_en}</span>
                        </div>
                    </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff]   lg:text-base  2xl:text-lg r my-3 inline-block w-full text-start'>
                                <StripTags policyText={banner?.text_en} />
                            </span>
                            <div className='grid md:grid-cols-2 gap-y-7 gap-x-8 mt-4 place-items-start'>
                                
                                {policy?.map((policy,id) => {     
                                    const isPrivacyPolicy = policy?.title_en?.replace(/-/g, '-') === 'privacy-policy';
                                    const link = policy?.link;
                                    return (                               
                                     <button key={id} className='py-1 px-6 w-full font-gotham-medium uppercase bg-secondary-500 border border-transparenthover:border-secondary-500 hover:bg-primary-500 hover:text-secondary-500 duration-300 ease-in-out
                                     rounded-tl-[10px] rounded-br-[10px] rounded-tr-[4px] rounded-bl-[4px] text-primary-500 text-xs xl:text-sm 2xl:text-base'>
                                       {/* <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">{policy?.title_en}</Link> 
                                        */}
                                            {link ? (
                                                <Link to={link} target="_blank" rel="noopener noreferrer">
                                                    {policy?.title_en}
                                                </Link>
                                            ) : (
                                                    <a href={policy?.pdf} download target="_blank">
                                                    {policy?.title_en}
                                                </a>
                                            )}
                                    </button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className='my-16'>
            <OurOffices />
        </div>   
    </>
  )
}

export default Policies