import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fixedService from "./fixedService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  section: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getFooter = createAsyncThunk(
  "section/getFooter",
  async (slug, thunkApi) => {
    try {
      return await fixedService.getFooter(slug);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const footerSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFooter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.section = action.payload;
      })
  },
});

export const { reset } = footerSlice.actions;
export default footerSlice.reducer;