import React, { useEffect, useState } from 'react'
import logo from '../assets/images/homescreen/logo.png'
import Line from '../shapes/Line'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFooter } from '../features/fixed-sections/fixedSlice'
import StripTags from '../utils/StripTags'
import { getSocial } from '../features/socials/socialSlice'
import axios from 'axios'
import config from '../config'
const Footer = () => {
    const [email,setEmail] = useState('');
    const [emailError,setEmailError] = useState('');
    const [success,setSuccess] = useState('');
    const {socials} = useSelector((state) => state.social)
    const {section} = useSelector((state) => state.section)

    const year = new Date().getFullYear();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getFooter())
         dispatch(getSocial())
    },[dispatch])

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        if (!validateForm()) {
            return;
        }  

        // const res = dispatch(createNewsLetter(email))

        const response = await axios.post(`${config.apiUrl}/api/newsletter`,{email});

        console.log(response)
        setSuccess(response.data);
        setTimeout(() => {
            setSuccess('');
        }, 3000);

        setEmail('');

    }

    const validateForm = () => {
        // Validate email
        if (!email || !isEmailValid(email)) {
          setEmailError('Invalid email format');
          setTimeout(() => {
            setEmailError('');
          }, 3000);
          
          setEmail('')
          return false;
        }


        return true;

    }

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

  return (
    <>
    <footer>
        <div className='bg-[#f7f9fa]'>
            <div className='screen-1 py-8 md:py-12'>
                <div className='flex flex-col lg:flex-row lg:justify-between'>
                    <div className='mt-[0.5rem] flex-initial'>
                        <Link to={'/'}>
                            <img width="100%" height="100%" onClick={scrollToTop} className='cursor-pointer' src={logo} alt="yellow-door-logo" />
                        </Link>
                        <div className='flex items-center space-x-4 lg:space-x-7 mt-4'>
                            {socials?.map((social,id) => (
                                <Link key={id} to={social?.link} target="_blank">
                                    <img width="100%" height="100%" key={id}  className='object-scale-down cursor-pointer' src={social?.icon} alt={social?.title}/>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className='md:w-[50rem]'>
                        <div className='text-primary-500 text-sm 2xl:text-lg my-6 lg:my-0 lg:px-[50px]'>
                           <StripTags policyText={section?.text}  />
                        </div>
                    </div>
                    <div className='w-25'> 
                        <div>
                            <h2 className=' font-gotham-black text-primary-500 text-sm 2xl:text-lg uppercase'>Site Map</h2>
                            <Line width={125} />
                            <div className='grid grid-cols-2 gap-y-3 gap-x-16 my-3'>
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/solutions/PPA-power-purchase-agreement'}>
                                        Power Purchase Agreements
                                    </Link>
                                </span>
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/solutions/battery-energy-storage-system'}>
                                        Battery Energy Storage System
                                    </Link>
                                </span>
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/projects'}>
                                        Projects
                                    </Link>
                                </span>
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/insights'}>
                                        Insights
                                    </Link>
                                </span>
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/sustainability'}>
                                        Sustainability
                                    </Link>
                                </span>
                                {/* <span className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>Markets</span> */}
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/careers'}>Careers</Link> 
                                </span>
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/our-story'}>Our Story</Link> 
                                </span>
                                <span onClick={scrollToTop} className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>
                                    <Link to={'/teams'}>Our Team</Link> 
                                </span>
                                {/* <span className='cursor-pointer text-sm 2xl:text-lg hover:text-secondary-500 text-primary-500'>About</span> */}
                            </div>
                        </div>
                        <div className='mt-6' >
                            <h2 className=' font-gotham-black text-primary-500 text-sm 2xl:text-lg uppercase'>NewsLetter</h2>
                            <Line width={175} />
                            <p className='text-primary-500 text-xs sm:text-sm my-3'>
                                Yes I’d like to subscribe to updates from Yellow Door Energy
                            </p>
                            <form onSubmit={handleSubmit}>
                                <div className='flex items-center'>  
                                    <input type='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' className='px-6 py-1 rounded-tl-[15px] text-sm 2xl:text-lg text-primary-500 bg-primary-500  bg-opacity-20 w-[60%] 2xl:w-2/5 sm:w-1/5 lg:w-full h-[29px] outline-none'/>
                                    <button type='submit' onClick={handleSubmit}  className='px-6 py-1 bg-primary-500 rounded-tl-[15px] rounded-br-[15px] -ms-4 hover:text-primary-500 font-gotham-medium border border-transparent hover:bg-[#ffffff] h-[29px] border-primary-500 duration-300 ease-in-out  text-[#ffffff] text-sm 2xl:text-lg'>
                                        Subscribe
                                    </button>
                                   
                                </div>
                                {emailError && ( <><div style={{color: 'red'}} className='text-sm 2xl:text-lg'>{emailError}</div></> )}
                                {success && ( <><div className='text-sm 2xl:text-lg text-secondary-500'>{success}</div></> )}   
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='screen-1 text-center py-4'>
            <span className='text-sm text-primary-500'>© {year} Yellow Door Energy. All rights reserved.</span>
        </div>
        </footer>
    </>
  )
}

export default Footer