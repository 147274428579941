import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'
import StripTags from '../utils/StripTags'
import moment from 'moment';
const SustainableEnergy = ({solution}) => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

  return (
    <>
        <div className='screen-1'>
            <div className='flex flex-col space-y-4 lg:space-y-0 lg:flex-row items-center justify-between'>
                <div className='w-full lg:w-3/4'>
                    <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Our</span>
                    <div className='flex items-center space-x-6'>
                        <h1 className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>
                            SUSTAINABLE<br/>
                            SOLAR ENERGY SOLUTIONS
                        </h1>
                    </div>
                    <div className='space-y-1 w-full mt-2'>
                        <Line width={60} />
                        <Line width={120} />
                    </div>
                </div>
                <div className='bg-primary-500 w-full py-6 px-12 md:px-28 border-4 border-secondary-500 rounded-bl-[100px] rounded-tr-[100px]'>
                    <p className='2xl:text-base text-sm sm:text-lg text-[#ffffff]'>
                        <StripTags policyText={solution?.text_en} />
                    </p>
                    <div className='text-end pt-4'><Link onClick={scrollToTop} to={'/solutions/PPA-power-purchase-agreement'} className=' 2xl:text-base text-sm text-[#ffffff] hover:text-secondary-500'>Learn More</Link></div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SustainableEnergy