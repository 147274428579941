import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'
const ShareHolders = ({shareholders}) => {
  return (
    <>
        <div className='screen-1 py-3'>
            <div className='flex items-end justify-between'>
                <div >
                    <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none '>Our</span>
                    <div className='flex flex-col md:flex-row items-center md:space-x-6'>
                        <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>
                            ShareHolders
                        </span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div >
                    <span className='md:text-base text-xs md:w-full inline-block '>
                        <Link to={'/our-story'} className='text-primary-500 hover:text-secondary-500'>Learn More</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className='screen-1 py-14'>
            <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-4'>
                {shareholders?.map((shareholder,id) => (
                    <div key={id}>
                        <img width="" height="" className='w-full h-48 object-contain' src={shareholder.image} alt='Shareholders'  />
                    </div>
                ))}
            </div>
        </div>
    </>
  )
}

export default ShareHolders