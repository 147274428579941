import React from 'react'
import logo from '../assets/images/homescreen/logo.png'
const LoadingComponent = () => {
  return (
    <>
        <div className="loading-container">
        <div className="loading-spinner"></div>
        <img className='load' src={logo} width={120} height={120} alt='Yellow Door Energy' />
        </div>
    </>
  )
}

export default LoadingComponent