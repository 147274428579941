import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../shapes/Line'

const Stories = ({projects}) => {
  return (
    <>
        <div className='screen-1  mt-16'>
            <div className='flex items-end justify-between py-3'>
                <div>
                    <span className=' uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Success</span>
                    <div className='flex flex-col md:flex-row items-center md:space-x-6'>
                        <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Stories</span>
                        <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                </div>
                <div >
                    <span className='md:text-base text-xs w-full inline-block'>
                        <Link to={'/projects'} className='text-primary-500 hover:text-secondary-500'>View All Projects</Link>
                    </span>
                </div>
            </div>
        </div>
        <div className='screen-1 pt-14 pb-4'>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4'>
            {projects?.map((project,id) => (
                <>
                {project?.isYoutube && (
                    <div key={id} className='sm:col-span-2'>
                            <div>
                            <iframe
                                title={project?.title_en}
                                width='100%'
                                height='300px'
                                src={`https://www.youtube.com/embed/${project?.isYoutube.split('/').pop()}`}
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            ></iframe>
                            </div>
                    </div>
                )}
                {project?.isYoutube == null && (
                    <>
                        <div>
                            <div>
                                <Link to={`/projects/${project?.slug}`}>
                                <div className='image-container'>
                                    <img src={project?.image} alt= {project?.title_en}  />
                                </div>
                                    <div className='py-4 px-5'>
                                        <p className='text-primary-500 font-gotham-bold uppercase text-base 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                            {project?.title_en}
                                        </p>
                                        <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.mwp_en}</p>
                                        <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.location_en}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>
                )} 
                    
                </>
            ))}
        </div>
        </div>
    </> 

  )
}

export default Stories