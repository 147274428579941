import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import insightService from "./insightService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  insights: [],
  tags: [],
  insight: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAllTags = createAsyncThunk(
  "insights/getAllTags",
  async (_, thunkApi) => {
    try {
      return await insightService.getAllTags();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getInsights = createAsyncThunk(
  "insights/getAll",
  async (page, thunkApi) => {
    try {
      return await insightService.getInsights(page);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 500) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getInsight = createAsyncThunk(
    "insights/getSingleInsight",
    async (slug, thunkApi) => {
      try {
        return await insightService.getInsight(slug);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
  );
  
export const getInsightByTags = createAsyncThunk(
    "insights/getInsightByTags",
    async ({id,page}, thunkApi) => {
      try {
        return await insightService.getInsightByTags(id,page);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
);

export const getOldestInsight = createAsyncThunk(
    "insights/getOldestInsight",
    async (page, thunkApi) => {
        try {
        return await insightService.getOldestInsight(page);
        } catch (error) {
        const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();

          if (error.response && error.response.status === 500) {
            thunkApi.dispatch(setServerError(true));
          } 

        return thunkApi.rejectWithValue(message);
        }
    }
);

export const getInsightsBySearch = createAsyncThunk(
    "insights/getInsightsBySearch",
    async ({search,page}, thunkApi) => {
      try {
        return await insightService.getInsightsBySearch(search,page);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 500) {
          thunkApi.dispatch(setServerError(true));
        } 
  
        return thunkApi.rejectWithValue(message);
      }
    }
  );

export const getInsightByAlpha = createAsyncThunk(
    "insights/getInsightByAlpha",
    async (page, thunkApi) => {
        try {
        return await insightService.getInsightByAlpha(page);
        } catch (error) {
        const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();

          if (error.response && error.response.status === 500) {
            thunkApi.dispatch(setServerError(true));
          } 
    
        return thunkApi.rejectWithValue(message);
        }
    }
);

export const projectSlice = createSlice({
  name: "insight",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsights.pending, (state) => {
        state.isLoading = true; 
        state.isSuccess = false; 
      })
      .addCase(getInsights.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.insights = action.payload;
      })
      .addCase(getInsightByTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.insights = action.payload;
      })
      .addCase(getOldestInsight.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.insights = action.payload;
      })
      .addCase(getInsightByAlpha.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.insights = action.payload;
      })
      .addCase(getInsightsBySearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.insights = action.payload;
      })
      .addCase(getInsight.pending, (state) => {
        state.isLoading = true; 
        state.isSuccess = false; 
      })
      .addCase(getInsight.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.insight = action.payload;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tags = action.payload;
      })
  },
});

export const { reset } = projectSlice.actions;
export default projectSlice.reducer;