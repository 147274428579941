import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LinkedIn from '../assets/images/homescreen/link.svg'
import Face from '../assets/images/homescreen/face.svg'
import Tweet from '../assets/images/homescreen/twitter.png'
import Line from '../shapes/Line'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { useDispatch, useSelector } from 'react-redux'
import { getInsight } from '../features/insights/insightSlice'
import moment from 'moment';
import { useState } from 'react'
import StripTags from '../utils/StripTags'
import LoadingComponent from '../components/LoadingComponent'
import SEO from '../components/SEO'
import LetsTalk from '../components/LetsTalk'

const InnerInsight = () => {

    const [detail,setDetail] = useState({});
    const [testimonial,setTestimonial] = useState([]);
    const [descriptions,setDescriptions] = useState([]);
    const [latestInsights,setLatestInsights] = useState([]);
    const [galleries,setGalleries] = useState([]);

    const { isLoading } = useSelector((state) => state.insight)

    const dispatch = useDispatch();

    const {slug} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const data = await dispatch(getInsight(slug))
            setDetail(data?.payload?.insight);
            setTestimonial(data?.payload?.testimonial);
            setDescriptions(data?.payload?.descriptions);
            setLatestInsights(data?.payload?.latestInsights);
            setGalleries(data?.payload?.galleries);

            if(data?.error) {
                navigate('/404')
            }
        }
        fetchData();

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
    });
    },[dispatch,slug])

   
    // const shareURL = `${config.shareUrl}`;
    const shareURL = window.location.href;
    const title = encodeURIComponent(detail?.title_en);
    const linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`;
    const facebookShareLink = `https://www.facebook.com/sharer.php?u=${shareURL}`;
    const twitterShareLink = `https://twitter.com/intent/tweet?url=${shareURL}&text=${title}&hashtags=yellowdoorenergy`;
    // const instagramShareLink = `https://www.instagram.com/?url=${encodeURIComponent(shareURL)}`;
   
  return (
    <>
     <SEO
        title={detail?.meta_title ? detail?.meta_title : detail?.title_en}
        description={detail?.text_en} 
        image={detail?.image}
        url={window.location.href}
     />
     {isLoading && (
        <div className={`loading-screen ${isLoading ? '' : 'hidden'}`}>
          <LoadingComponent />
        </div>
      )}
        <div className='mt-[100px] lg:mt-[140px]'>
            <div className='screen-1'>
                <div className='grid lg:grid-cols-2'>
                    <section className='md:col-span-1 lg:w-full 2xl:w-[700px]'>
                        <div><img width="329" height="182" className='w-full h-full lg:h-[330px] object-cover' src={detail?.image} alt={detail?.title_en} /></div>
                        <div className='grid lg:grid-cols-3 mt-4 mb-2'>
                            <div className='lg:col-span-2 text-primary-500 font-gotham-bold text-lg 2xl:text-2xl w-[90%]'>
                                <h1>{detail?.title_en}</h1>
                            </div>
                            <div id="share-buttons" className='flex space-x-4 lg:justify-self-end items-baseline my-2 lg:my-0 lg:mx-4'>
                                <span>Share:</span>
                                <a href={linkedInShareLink} target="_blank" >
                                    <img width="" height="" className='object-contain w-[15px] h-[15px]' src={LinkedIn} alt='LinkedIn' />
                                </a>
                                <a href={facebookShareLink} target="_blank">
                                    <img width="" height="" className='object-contain w-[15px] h-[15px]' src={Face} alt='Facebook' />
                                </a>
                                <a href={twitterShareLink} target="_blank">
                                    <img width="" height="" className='object-contain w-[15px] h-[15px]' src={Tweet} alt='Twitter' />
                                </a>
                            </div>
                        </div>

                        <div className='text-[#101010] text-sm 2xl:text-lg mb-6'>
                            {detail?.insight?.title_en} | {moment(detail?.date).format('LL')}
                        </div>
                        <div className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list hyperlink leading-text'>
                            <StripTags policyText={detail?.text_en} />
                        </div>

                        {testimonial?.length > 0 && (
                            <div className='my-12 py-12 flex flex-col items-center justify-center w-full border-2 min-h-[280px] border-secondary-500 rounded-tr-[100px] rounded-bl-[100px]'>
                              <p className='text-primary-500 w-3/4 mx-auto'>
                                  <StripTags policyText={testimonial[0]?.text_en} />
                              </p>
                              <div className='text-[#101010] text-base w-3/4 mx-auto pt-8'>
                                  <p className='font-gotham-bold'>{testimonial[0]?.name}</p>
                                  <p>{testimonial[0]?.role}</p>
                              </div>
                          </div>
                        )}
                      

                        {descriptions?.map((description,id) => (
                            <>
                                <figure className='mt-8'>
                                    <img width="550" height="300" className='w-[500px] h-full object-cover rounded-tr-[100px] rounded-bl-[100px]' src={description?.image} alt={description?.text_en}  />
                                </figure>
                                <div key={id} className='text-sm 2xl:text-lg text-[#101010] mt-4 mb-12 text-primary-500 custom-list'>
                                    <p className='mt-2 mb-3'><StripTags policyText={description?.text_en} /></p>
                                </div>
                            </>
                        ))}  


                        {testimonial?.length > 1 && (
                            <div className='my-12 py-12 flex flex-col items-center justify-center w-full border-2 min-h-[280px] border-secondary-500 rounded-tr-[100px] rounded-bl-[100px]'>
                              <p className='text-primary-500 w-3/4 mx-auto'>
                                  <StripTags policyText={testimonial[1]?.text_en} />
                              </p>
                              <div className='text-[#101010] text-base w-3/4 mx-auto pt-8'>
                                  <p className='font-gotham-bold'>{testimonial[1]?.name}</p>
                                  <p>{testimonial[1]?.role}</p>
                              </div>
                          </div>
                        )}    

                        {detail?.pdf && (
                            <div className='flex items-center my-12 space-x-10'>
                                <>
                                    <span>Learn More</span>
                                    <Link to={detail?.pdf} target="_blank">
                                    <button className='uppercase text-base 2xl:text-lg rounded-tl-[10px] font-gotham-medium rounded-br-[10px] text-primary-500 bg-secondary-500 hover:bg-primary-500 
                                        w-[150px] hover:text-secondary-500 border border-secondary-500 duration-300 ease-in'>PDF</button></Link>
                                </>
                            </div>
                        )}
                        {detail?.projects?.length > 0 && (
                            <section className='my-12'>
                                <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-4'>
                                    {detail?.projects?.map((project,id) => (
                                    <>
                                        {project?.isYoutube && (
                                            <div key={id} className='sm:col-span-2'>
                                                    <div>
                                                    <iframe
                                                        title={project?.title_en}
                                                        width='100%'
                                                        height='400px'
                                                        src={`https://www.youtube.com/embed/${project?.isYoutube.split('/').pop()}`}
                                                        frameBorder='0'
                                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                        allowFullScreen
                                                    ></iframe>
                                                    </div>
                                            </div>
                                        )}
                                        {project?.isYoutube == null && (
                                            <>
                                                <div>
                                                    <div>
                                                        <Link to={`/projects/${project?.slug}`}>
                                                            <img width="329" height="182" className='w-full h-full object-contain' src={project?.image} alt={project?.title_en}  />
                                                            <div className='py-4 sm:px-5'>
                                                                <p className='text-primary-500 font-gotham-bold uppercase text-sm 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                                                    {project?.title_en}
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            
                                            </>
                                        )}     
                                        </>
                                    ))}
                                </div>
                            </section>
                        )}
                        {galleries?.length > 0 && (
                            <section className='screen-1 my-16'>
                            <div className='grid grid-cols-1 gap-4'>
                                {galleries?.map((gallery,id) => (
                                    <>
                                    {gallery?.isYoutube && (
                                        <div key={id}>
                                                <div>
                                                <iframe
                                                    title={'Yellow Door Energy'}
                                                    width='100%'
                                                    height='400px'
                                                    src={`https://www.youtube.com/embed/${gallery?.isYoutube.split('/').pop()}`}
                                                    frameBorder='0'
                                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                    allowFullScreen
                                                ></iframe>
                                                </div>
                                        </div>
                                    )}
                                    {gallery?.isYoutube == null && (
                                        <>
                                            <div>
                                                <div>
                                                    <a href={gallery?.image}  data-lightbox={`image-${id}`} data-title={`${gallery?.title_en}`}>
                                                        <img width="" height="" className='w-full h-full object-contain' src={gallery?.image} alt='Project Galleries'  />
                                                        <div className='py-4 sm:px-5'>
                                                            <p className='text-primary-500 font-gotham-bold uppercase text-sm 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                                                {gallery?.title_en}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </>
                                    )} 
                                        
                                    </>
                                ))}
                            </div>
                        </section>
                        )}
                                         
                            
                    </section>


                    
                <section className='md:pl-[5.5rem]'>
                    <div>
                        <span className='uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Latest</span>
                        <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                            <span className='uppercase text-primary-500 2xl:text-3xl text-xl font-gotham-black'>Insights</span>
                            <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                    {latestInsights?.map((insight,id) => (
                        <div key={id} className='flex flex-col md:flex-row items-center space-x-4 my-3'>
                            {/* <img width="220" height="100" className='w-full md:w-[220px] h-[185px] my-6 md:my-0 object-contain' src={insight?.image} alt={insight?.title_en} /> */}
                            <div>
                            <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500 '>
                                <p className='text-primary-500 font-gotham-bold uppercase lg:text-sm text-base hover:text-secondary-500 cursor-pointer duration-300 ease-in-out'>
                                    {insight?.title_en}
                                </p>
                            </Link>
                                <p className=' 2xl:text-base text-sm'>{insight?.insight?.title_en}</p>
                                <p className='text-xs text-primary-500 text-opacity-50 my-2'>{moment(insight?.date).format('LL')}</p>
                                <div>
                                    <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500 '>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='sticky top-[120px] hidden lg:block my-12'>
                        <LetsTalk />
                    </div>
                </section>
            </div>
            </div>
            <div className='mb-16'>
                <Contact />
            </div>

            <div className='mt-16'>
                <OurOffices />
            </div>
       
    </div>
    </>
  )
}

export default InnerInsight