import React from 'react'
import { Helmet } from 'react-helmet-async';

const SEO = ({title, description, name, type,image,url,href}) => {

  return (

    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta property="og:url" content={url} />

      {/* <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} /> */}

      <link rel='canonical' href={url} />

      {href == 1 ? (
        <>  
          <link rel="alternate" hreflang="en-ae" href="https://www.yellowdoorenergy.com/markets/uae"/>
          <link rel="alternate" hreflang="en-jo" href="https://www.yellowdoorenergy.com/markets/jordan"/>
          <link rel="alternate" hreflang="en-za" href="https://www.yellowdoorenergy.com/markets/south-africa"/>
          <link rel="alternate" hreflang="en-sa" href="https://www.yellowdoorenergy.com/markets/saudi-arabia"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/bahrain"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/oman"/>
        </>
      ) : href == 2 ? (
        <>
          <link rel="alternate" hreflang="en-jo" href="https://www.yellowdoorenergy.com/markets/jordan"/>
          <link rel="alternate" hreflang="en-ae" href="https://www.yellowdoorenergy.com/markets/uae"/>
          <link rel="alternate" hreflang="en-za" href="https://www.yellowdoorenergy.com/markets/south-africa"/>
          <link rel="alternate" hreflang="en-sa" href="https://www.yellowdoorenergy.com/markets/saudi-arabia"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/bahrain"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/oman"/>
        </>
      ) : href == 3 ? (
        <>
          <link rel="alternate" hreflang="en-za" href="https://www.yellowdoorenergy.com/markets/south-africa"/>
          <link rel="alternate" hreflang="en-ae" href="https://www.yellowdoorenergy.com/markets/uae"/>
          <link rel="alternate" hreflang="en-jo" href="https://www.yellowdoorenergy.com/markets/jordan"/>
          <link rel="alternate" hreflang="en-sa" href="https://www.yellowdoorenergy.com/markets/saudi-arabia"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/bahrain"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/oman"/>
        </>
      ) : href == 4 ? (
        <>
          <link rel="alternate" hreflang="en-sa" href="https://www.yellowdoorenergy.com/markets/saudi-arabia"/>
          <link rel="alternate" hreflang="en-ae" href="https://www.yellowdoorenergy.com/markets/uae"/>
          <link rel="alternate" hreflang="en-jo" href="https://www.yellowdoorenergy.com/markets/jordan"/>
          <link rel="alternate" hreflang="en-za" href="https://www.yellowdoorenergy.com/markets/south-africa"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/bahrain"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/oman"/>
        </>
      ) : href == 5 ? (
        <>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/bahrain"/>
          <link rel="alternate" hreflang="en-ae" href="https://www.yellowdoorenergy.com/markets/uae"/>
          <link rel="alternate" hreflang="en-jo" href="https://www.yellowdoorenergy.com/markets/jordan"/>
          <link rel="alternate" hreflang="en-za" href="https://www.yellowdoorenergy.com/markets/south-africa"/>
          <link rel="alternate" hreflang="en-sa" href="https://www.yellowdoorenergy.com/markets/saudi-arabia"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/oman"/>
        </>
      ) : href == 6 ? (
        <>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/oman"/>
          <link rel="alternate" hreflang="en-ae" href="https://www.yellowdoorenergy.com/markets/uae"/>
          <link rel="alternate" hreflang="en-jo" href="https://www.yellowdoorenergy.com/markets/jordan"/>
          <link rel="alternate" hreflang="en-za" href="https://www.yellowdoorenergy.com/markets/south-africa"/>
          <link rel="alternate" hreflang="en-sa" href="https://www.yellowdoorenergy.com/markets/saudi-arabia"/>
          <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/bahrain"/>
        </>
      ) : null}


  
    </Helmet>

  )
}

export default SEO