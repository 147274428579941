import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsLetterService from "./newsletterService";
import { setServerError } from "../error500/error500Slice";


const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const createNewsLetter = createAsyncThunk(
  "newsletter/newsletterSubmit",
  async (data, thunkApi) => {
    try {
      return await newsLetterService.newsLetterSubmit(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && (error.response.status === 500 || error.response.status === 503 )) {
        thunkApi.dispatch(setServerError(true));
      } 

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewsLetter.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
  },
});

export const { reset } = newsletterSlice.actions;
export default newsletterSlice.reducer;