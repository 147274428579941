import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/applications`;

const getProducts = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const getApp = async (slug) => {
  const response = await axios.get(API_URL + `/${slug}`);
  return response.data;
};

const productsService = {
  getProducts,
  getApp
};

export default productsService;