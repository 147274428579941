import React, { useEffect } from 'react'
import Benefits from '../components/Benefits';
import HowWorks from '../components/HowWorks';
import Contact from '../components/Contact';
import OurOffices from '../components/OurOffices';
import { useDispatch, useSelector } from 'react-redux';
import { getBenefits } from "../features/solutions/solutionSlice";
import Line from '../shapes/Line';
import { useLocation } from 'react-router-dom';
import { getBanner } from '../features/banners/bannerSlice';
import StripTags from '../utils/StripTags';

const Solutions = () => {

    const { banner } = useSelector((state) => state.banner);
    const { benefits } = useSelector((state) => state.solution);
    const dispatch = useDispatch();

   
    const location = useLocation();
    const slug = location?.pathname.split('/')[1];
  
    useEffect(() => {
        dispatch(getBanner(slug));
    },[dispatch])

    useEffect(() => {
        dispatch(getBenefits());
     
    },[dispatch])

  return (
    <>
       <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[344px]'>
                <img width="1600" height="800" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[36%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[76%] w-full '>
                </div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                    <div className='lg:ml-auto lg:w-[70%] lg:pl-[30px]'>
                        <div className='w-full'>
                            <span className='uppercase text-[#ffffff] text-2xl lg:text-xl font-gotham-bold'>{banner?.title_en}</span>
                        </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff] lg:text-base  2xl:text-lg my-3 inline-block '>
                              <StripTags policyText={banner?.text_en} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='my-16'>
            <div className='screen-1'>
                <div className='flex flex-col lg:flex-row lg:justify-between  py-3 '>
                    <div >
                        <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Your</span>
                        <div className='flex flex-col'>
                            <span className='uppercase w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Benefits</span>
                            <div className='space-y-1 w-full mt-2'>
                                <Line width={60} />
                                <Line width={120} />
                            </div>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-2 gap-12 w-full mt-12 lg:mt-0 lg:w-[66.5%]'>
                        {benefits?.map(({text_en}, id) => (
                            <Benefits key={id} text={text_en} />
                        ))}
                    </div>
                </div>
            </div>
        </div>

        <div className='my-16'>
            <HowWorks />
        </div>

        <div className='my-16'>
            <Contact />
        </div>

        <div className='my-16'>
            <OurOffices />
        </div>

    </>
  )
}

export default Solutions